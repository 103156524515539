import md5 from "md5";
import { SecurityUtils } from "revlock-webutils";
import { getPlan } from "./auth";
import { setData2 } from "../redux/actions/data.actions";
import { Auth } from "aws-amplify";
import store from "../redux/ReduxStore";

const readUserData = async () => {
    try {
        const authUser = await Auth.currentAuthenticatedUser({
            bypassCache: true
        });

        const rawData = authUser.attributes["custom:data"];
        if (rawData && rawData.length > 0) {
            const data = JSON.parse(rawData);
            return { data, authUser };
        }

        return { data: null };
    } catch (e) {
        console.warn("Failed to read user data ", e);
        return { data: null };
    }
};

export const markSeenTour = async (orgId, tour = "welcome_tour", history) => {
    let { data } = await readUserData();

    if (!data) {
        data = { tours: [] };
    }

    if (!data.tours) {
        data.tours = [];
    }

    data.tours.push(tour);

    try {
        const authUser = await Auth.currentAuthenticatedUser();

        if (authUser) {
            if (authUser.attributes["custom:data"] == undefined) {
                await Auth.updateUserAttributes(authUser, {
                    "custom:data": JSON.stringify(data)
                });
                store.dispatch({
                    type: "SET_TOUR"
                });
            }

            if (getPlan().name == "rev-essential") {
                // Redirect to the QuickView page.
                history.push(`/${orgId}/reports/quickview/revenue`);
            }
        } else {
            console.warn("Unable to find authUser to mark tour state");
        }
    } catch (e) {
        console.warn("Failed to update attributes due to", e);
    }
};

export const hasSeenTour = (tour = "welcome_tour", currentUser) => {
    const { data } = currentUser;
    return (data && data.tours.find((n) => n == tour)) || false;
};

export const markSeenACTour = async (orgId, tour = "onboarding_tour", history) => {
    let { data } = await readUserData();

    if (!data) {
        data = { acTours: [] };
    }

    if (!data.acTours) {
        data.acTours = [];
    }

    data.acTours.push(tour);

    try {
        const authUser = await Auth.currentAuthenticatedUser();

        if (authUser) {
            await Auth.updateUserAttributes(authUser, { 'custom:data': JSON.stringify(data) });
            store.dispatch({
                type: 'SET_AC_TOUR'
            })

        } else {
            console.error("Unable to find authUser to mark AC tour guide state");
        }
    } catch (e) {
        console.error("Failed to update attributes due to", e.errorMessage || e.text || e.message);
    }
}


export const hasSeenACTour = (tour = "onboarding_tour", currentUser) => {
    const { data } = currentUser;
    return (data && data.acTours?.find((n) => n == tour)) || false;
};