import { moment } from "./moment";

export class SecurityUtils {
    static SUPER_ADMIN_LEVEL_1 = 1;
    static SUPER_ADMIN_LEVEL_2 = 2;
    static SUPER_ADMIN_LEVEL_3 = 3;
    static SUPER_ADMIN_LEVEL_4 = 4;

    static allRoles() {
        return this.getAllRoles().map((item) => item.name);
    }

    static getAllRoles() {
        return [
            {
                name: "Admin",
                description:
                    "User has access to manage users, setup data connections, and site configurations."
            },
            {
                name: "Analyst",
                description:
                    "User has access to review reports, view the customers and other UI tabs, update data and review journal entries results."
            },
            {
                name: "Controller",
                description:
                    "User has access to Journal Posting, Accounting Close, and GL setup."
            }
        ];
    }

    static getRoles(clientId, user) {
        if (!user || !clientId) return [];
        let orgUserAuth = this.getOrgUserAuth(clientId, user);
        let roles = orgUserAuth && orgUserAuth.roles && orgUserAuth.roles;
        return (roles && roles.map((r) => r.toLowerCase())) || [];
    }

    static getSuperAdminLevel(user) {
        if (!user) {
            return 0;
        }

        if (user.superAdminLevel !== undefined) {
            return user.superAdminLevel;
        } else {
            const attributes = user["attributes"] || user["UserAttributes"];

            if (attributes && attributes["custom:super-admin"]) {
                const superAdmin = attributes["custom:super-admin"];

                if (superAdmin === "false") {
                    return 0;
                } else if (superAdmin === "true") {
                    return SecurityUtils.SUPER_ADMIN_LEVEL_1;
                } else {
                    return parseInt(superAdmin);
                }
            } else {
                return 0;
            }
        }
    }

    static isInternalUser(user) {
        if (!user || !user.email) {
            return 0;
        }

        if (
            user.email.includes("@chargebee.com") ||
            user.email.includes("@rev-lock.com")
        ) {
            return true;
        }

        return false;
    }

    static hasRole(clientId, role, user) {
        if (!user || !clientId) {
            return false;
        }

        if (
            SecurityUtils.getSuperAdminLevel(user) >=
            SecurityUtils.SUPER_ADMIN_LEVEL_1
        ) {
            return true;
        }

        const roles = this.getRoles(clientId, user);

        if (roles && roles.length > 0) {
            // If admin then user has all the access he needs
            if (roles.includes("admin") || roles.includes("Admin")) {
                return true;
            }

            return roles.includes(role.toLowerCase()) || false;
        } else {
            return false;
        }
    }

    static hasHotGlueAccess(clientId, user) {
        return (
            SecurityUtils.getSuperAdminLevel(user) >=
                SecurityUtils.SUPER_ADMIN_LEVEL_1 ||
            SecurityUtils.hasRole(clientId, "Admin", user)
        );
    }

    static hasOrgAccess(clientId, user) {
        return (this.getOrgUserAuth(clientId, user) && true) || false;
    }

    static getOrgUserAuth(clientId, user) {
        if (!user || !clientId) return;

        const userAuth = user.authorizations || [];
        return userAuth.find((auth) => auth.org == clientId);
    }

    static allPlans() {
        const plans = {
            "rev-essential": {
                level: 0,
                name: "rev-essential",
                label: "Rev-QuickView",
                description: "Chargebee Reporting plan",
                longDescription: "Only tracks Deferred Revenue.",
                price: "Contact Us",
                term: "monthly",
                starting: true,
                type: "plan",
                image:
                    "https://cdn.revrec.chargebee.com/licenses/rev-start.png",
                nextPlan: "rev-up",
                trialPlan: "rev-up",
                trialPeriod: 30
            },
            "rev-start": {
                level: 1,
                name: "rev-start",
                label: "Performance",
                description: "Our starter plan",
                longDescription: "Best for Deferred Revenue Tracking.",
                price: "Contact Us",
                term: "monthly",
                starting: true,
                type: "plan",
                image:
                    "https://cdn.revrec.chargebee.com/licenses/rev-start.png",
                nextPlan: "rev-up"
            },
            "rev-up": {
                level: 2,
                name: "rev-up",
                label: "Enterprise",
                description: "Our enterprise plan",
                longDescription: "Best for fast growing companies.",
                price: "Contact Us",
                starting: true,
                type: "plan",
                image: "https://cdn.revrec.chargebee.com/licenses/rev-rnrl.png"
            }
        };

        return plans;
    }

    static getEssentialPlan() {
        const plans = SecurityUtils.allPlans();

        for (let plan of Object.keys(plans)) {
            if (plans[plan].level == 0) {
                return plans[plan];
            }
        }

        console.error("Unable to find essentials license plan");
        return null;
    }

    static getDefaultPlan() {
        const plans = SecurityUtils.allPlans();

        for (let plan of Object.keys(plans)) {
            if (plans[plan].level == 1) {
                return plans[plan];
            }
        }

        console.error("Unable to find default license plan");
        return null;
    }

    static getEnterprisePlan() {
        const plans = SecurityUtils.allPlans();

        for (let plan of Object.keys(plans)) {
            if (plans[plan].level === 2) {
                return plans[plan];
            }
        }
        console.error("Unable to find enterprise license plan");
        return null;
    }

    static getTrial(activePlan) {
        if (activePlan && activePlan.trial) {
            const trialDate = activePlan.trialStartedOn
                ? activePlan.trialStartedOn
                : activePlan.addedOn;
            let trialPeriod = activePlan.trialPeriod
                ? activePlan.trialPeriod
                : 30;
            const trialExpiredOn = activePlan.trialExpiredOn
                ? activePlan.trialExpiredOn
                : undefined;

            if (trialExpiredOn) {
                trialPeriod = moment(trialExpiredOn).diff(
                    moment(trialDate),
                    "days"
                );
            }

            const days = moment().diff(moment(trialDate), "days");

            return { isTrial: true, daysRemaining: trialPeriod - days };
        }

        return { isTrial: false };
    }

    static getPlan(org) {
        if (org && !org.license) {
            // Assign a default license if none is set on the org.
            let license = JSON.parse(
                JSON.stringify(SecurityUtils.getDefaultPlan())
            );
            license.trial = false;
            license.addedOn = new Date().toISOString();
            const defaultLicense = {
                license: license
            };

            org = Object.assign(org || {}, defaultLicense);
        }

        const activePlan = org.license;
        const trial = SecurityUtils.getTrial(activePlan);
        const plan = (activePlan && activePlan.name) || "rev-start";
        const plans = SecurityUtils.allPlans();

        return Object.assign(plans[plan], activePlan, trial);
    }

    static shouldAdminOptionDisabled(org, currentUser) {
        if (currentUser == undefined) {
            return true;
        }
        // If you are running locally the options will be enabled
        // If org stage is Internal then the options will be enabled
        // If user is invited then options will be enabled
        return window.location.hostname != "localhost"
            ? org.stage != "Internal" &&
                  !org?.users?.some(
                      (user) => user.username === currentUser.email
                  )
            : false;
    }
}
