import React, { useState } from "react";
import clsx from "clsx";
import { Paper, Fade, Slide, Grid, Popover } from "@material-ui/core";
import { withCookies } from "react-cookie";
import config from "cfg/config";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { compose } from "utils/WebUtils";
import { withRouter } from "react-router-dom";

// Material components
import { yellow } from "@material-ui/core/colors";

const drawerWidth = 280;

import {
    Box,
    Toolbar,
    Typography,
    Drawer,
    AppBar,
    IconButton,
    Tooltip,
    CircularProgress,
    MenuList,
    MenuItem
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { setData2 } from "redux/actions/data.actions";

import {
    Business as BusinessIcon,
    Language as LanguageIcon,
    SwapHoriz as ArrowDown,
    Warning as WarningIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    Help as HelpIcon,
    MoreHorizTwoTone as MoreIcon,
    Apps as AppsIcon
} from "@material-ui/icons";
import { currentOrganization, loadingOrg } from "redux/selectors/user.selector";
import DocumentationIcon from "@material-ui/icons/Description";
import DraftsIcon from "@material-ui/icons/Drafts";

// Portal components
import OrganizationSidenav from "../../components/notification-sidenav/organization-sidenav.component";

import chargeBeeIcon from "assets/images/chargebee-icon-orange.png";
import rrLogo from "assets/images/revrec-logo.png";
import actgLogo from "assets/images/actg-logo.png";

// Actions
import {
    toggleSidenav,
    toggleOrganizations
} from "redux/actions/layout.actions";
import UserActions from "redux/actions/user.actions";

import { AccountingUtils } from "revlock-accounting";
import UserMenu from "components/user/UserMenu";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { addAuditEvent, getOrgConfigurations } from "rest/API";
import MenuSidenavCompact from "layouts/components/menu-sidenav-compact/menu-sidenav-compact.component";
import { orgConfig } from "redux/selectors";

function ProductSwitcher(props) {
    const {
        classes,
        layout,
        currentOrganization,
        space,
        acEnabled,
        acOrgId,
        rrEnabled,
        rrOrgId,
        setSpace,
        history
    } = props;
    const [appAnchorEl, setAppAnchorEl] = React.useState(null);

    function openAppSwitch(event) {
        setAppAnchorEl(event.currentTarget);
    }

    function closeAppSwitch() {
        setAppAnchorEl(null);
    }

    function handleSpaceUpdate(space) {
        setSpace(space);
        const newUrl =
            rrEnabled && space == "revrec"
                ? `/${rrOrgId || currentOrganization.id}/dashboards`
                : `/ac/${acOrgId || currentOrganization.id}`;
        history.push(newUrl);

        setData2("productSpace", space);
    }

    return (
        <>
            <List id="productSwitcher" component="nav" disablePadding>
                <Tooltip
                    title={layout.sidenavOpen ? "" : "Chargebee Products"}
                    placement="right"
                >
                    <ListItem
                        onClick={openAppSwitch}
                        button
                        className={classes.listItemText}
                        classes={{
                            root: classes.bottomListItemRootOverrides,
                            gutters: classes.listItemGuttersOverrides
                        }}
                    >
                        <ListItemIcon
                            classes={{
                                root: classes.bottomListItemIconOverrides
                            }}
                        >
                            <b>
                                <AppsIcon className={classes.listIcon} />
                            </b>
                        </ListItemIcon>
                        <ListItemText disableTypography>
                            <b>Chargebee Products</b>
                        </ListItemText>
                    </ListItem>
                </Tooltip>
            </List>
            <AppSelectorPopOver
                space={space}
                onUpdate={handleSpaceUpdate}
                onClose={closeAppSwitch}
                open={Boolean(appAnchorEl)}
                anchorEl={appAnchorEl}
                acEnabled={acEnabled}
                acOrgId={acOrgId}
                rrEnabled={rrEnabled}
                rrOrgId={rrOrgId}
                classes={classes}
                currentOrganization={currentOrganization}
            />
        </>
    );
}

function HelpMenu(props) {
    const { classes, layout, currentOrganization } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const contactSupport = () => {
        addAuditEvent(currentOrganization.id, {
            eventType: "contact_support_clicked",
            entityType: "contact_support",
            entityId: "Contact Support",
            message: `User clicked to send Contact Support email`
        });
        window.open("mailto:revrec-support@chargebee.com");
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <>
            <List id="help" component="nav" disablePadding>
                <Tooltip
                    title={layout.sidenavOpen ? "" : "Need Help"}
                    placement="right"
                >
                    <ListItem
                        onClick={handleClick}
                        button
                        className={classes.listItemText}
                        classes={{
                            root: classes.bottomListItemRootOverrides,
                            gutters: classes.listItemGuttersOverrides
                        }}
                    >
                        <ListItemIcon
                            classes={{
                                root: classes.bottomListItemIconOverrides
                            }}
                        >
                            <HelpIcon className={classes.listIcon} />
                        </ListItemIcon>
                        <ListItemText disableTypography>Need Help</ListItemText>
                        <ListItemIcon
                            classes={{ root: classes.listItemIconOverrides }}
                        >
                            <MoreIcon className={classes.listIcon} />
                        </ListItemIcon>
                    </ListItem>
                </Tooltip>
            </List>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                style={{
                    left: 20,
                    top: 20
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
            >
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    className={classes.helpBox}
                >
                    <MenuList component="nav" aria-label="main mailbox folders">
                        <MenuItem
                            button
                            onClick={() => {
                                contactSupport();
                            }}
                        >
                            <ListItemIcon className={classes.icon}>
                                <DraftsIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography
                                variant="inherit"
                                className={`${classes.menuItemTitle}`}
                            >
                                Contact Support
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            button
                            onClick={() => {
                                window.open(
                                    "https://www.chargebee.com/docs/revrec/quick-start.html"
                                );
                            }}
                        >
                            <ListItemIcon className={classes.icon}>
                                <DocumentationIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography
                                variant="inherit"
                                className={`${classes.menuItemTitle}`}
                            >
                                Documentation
                            </Typography>
                        </MenuItem>
                    </MenuList>
                </Box>
            </Popover>
        </>
    );
}

const Sidenav = (props) => {
    const {
        layout,
        classes,
        cookies,
        siteType,
        acEnabled,
        acOrgId,
        rrEnabled,
        rrOrgId,
        acSegregateByBusinessEntity,
        ...rest
    } = props;
    const [orgNav, setOrgNav] = useState(false);
    const [space, setSpace] = useState(
        siteType == "ac" ||
            rrEnabled == false ||
            props.location.pathname.startsWith("/ac/")
            ? "ac"
            : "revrec"
    );

    React.useEffect(() => {
        setSpace(
            siteType == "ac" ||
                rrEnabled == false ||
                props.location.pathname.startsWith("/ac/")
                ? "ac"
                : "revrec"
        );
    }, [rrEnabled, siteType]);

    const renderEnvironments = () => {
        const { classes, currentOrganization, history } = props;
        if (!currentOrganization) return null;

        const isTest = AccountingUtils.isTestId(currentOrganization.id);
        const isConsolidateOrg = AccountingUtils.isConsolidatedId(
            currentOrganization.id
        );
        const isConsolidateTestOrg = AccountingUtils.isConsolidatedTestId(
            currentOrganization.id
        );

        const label = currentOrganization.name; //+ (isProd ? ' - Production' : ' - Test')

        return (
            <TenantSelectorDropDown
                id={"environments"}
                classes={classes}
                title={label}
                subTitle={
                    isTest
                        ? "Test"
                        : isConsolidateOrg
                        ? "Consolidated"
                        : isConsolidateTestOrg
                        ? "Consolidated Test"
                        : "Production"
                }
                clickable={true}
                isPrimary={!isTest}
                style={{ paddingLeft: "29px" }}
                orgNav={setOrgNav}
                space={space}
                setSpace={setSpace}
                currentOrganization={currentOrganization}
                history={history}
                acEnabled={acEnabled}
                acOrgId={acOrgId}
                rrEnabled={rrEnabled}
                rrOrgId={rrOrgId}
            />
        );
    };

    const open = layout.sidenavOpen;

    const currentYear = new Date().getFullYear();

    const currentServiceProfile =
        window.location.hostname === "localhost"
            ? cookies.get("simulatedLocalRedirectProfile") ||
              config.serviceProfile
            : config.serviceProfile;

    const businessEntities = props.currentOrganization.businessEntities;
    const [openEntity, setOpenEntity] = useState(false);
    const [entityAnchorEl, setEntityAnchorEl] = React.useState(null);
    const [selectedBusinessEntity, setSelectedBusinessEntity] = React.useState(
        (props.currentOrganization.businessEntities &&
            props.currentOrganization.businessEntities.length > 0 &&
            props.currentOrganization.businessEntities[0]) ||
            {}
    );

    React.useEffect(() => {
        if (
            (!selectedBusinessEntity ||
                Object.keys(selectedBusinessEntity).length == 0) &&
            props.currentOrganization.businessEntities &&
            props.currentOrganization.businessEntities.length > 0
        ) {
            setSelectedBusinessEntity(
                props.currentOrganization.businessEntities[0]
            );
        }
    }, [props.currentOrganization.businessEntities, selectedBusinessEntity]);

    const onEntitySwitcherClick = (e) => {
        setEntityAnchorEl(openEntity ? null : e.currentTarget);
        setOpenEntity(!openEntity);
    };

    const onEntityClick = (e, entityId) => {
        setOpenEntity(!openEntity);
        props.currentOrganization.be_id = entityId;
        UserActions.setCurrentOrganization(props.currentOrganization, false);

        for (const be of props.currentOrganization.businessEntities) {
            if (be.Business_Entity_Id == entityId) {
                setSelectedBusinessEntity(be);
                break;
            }
        }
    };

    return (
        <Drawer
            classes={{
                paper: clsx(classes.drawerOverride, {
                    [classes.sideNavOpen]: open,
                    [classes.sideNavDocked]: !open
                })
            }}
            className={clsx(classes.sideNav, {
                [classes.sideNavOpen]: open,
                [classes.sideNavDocked]: !open
            })}
            variant="permanent"
            elevation={20}
            open={layout.sidenavOpen}
            onClose={props.toggleSidenav}
        >
            {/* top button to change environments */}
            {open && (
                <div class={classes.toolBarGutterOverrides}>
                    {renderEnvironments()}
                </div>
            )}

            {acSegregateByBusinessEntity && space == "ac" && (
                <div class={classes.entityToolBarGutterOverrides}>
                    <div className={classes.dropDownMenuPrimary}>
                        <div>
                            <div
                                className={classes.orgSwitcher}
                                onClick={(e) => {
                                    onEntitySwitcherClick(e);
                                }}
                            >
                                <Paper sx={{ my: 1, mx: "auto", p: 2 }}>
                                    <Grid container wrap="nowrap">
                                        <Grid item>
                                            <Box
                                                display={"flex"}
                                                alignItems={"center"}
                                            >
                                                <BusinessIcon />
                                            </Box>
                                        </Grid>
                                        <Grid item xs className={classes.ptb0}>
                                            <div
                                                className={
                                                    classes.dpTextContainer
                                                }
                                                style={{ textAlign: "left" }}
                                            >
                                                <Tooltip
                                                    title={
                                                        (selectedBusinessEntity &&
                                                            selectedBusinessEntity.Name) ||
                                                        "Unknown"
                                                    }
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        className={`${classes.orgTitle} ${classes.orgTitleSidenav}`}
                                                        align="left"
                                                    >
                                                        {(selectedBusinessEntity &&
                                                            selectedBusinessEntity.Name) ||
                                                            "Unknown"}
                                                    </Typography>
                                                </Tooltip>
                                                <Tooltip
                                                    title={
                                                        (selectedBusinessEntity &&
                                                            selectedBusinessEntity.Timezone) ||
                                                        "Unknown"
                                                    }
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        className={`${classes.dpSubtitle} ${classes.orgTitleSidenav}`}
                                                        align="left"
                                                    >
                                                        {(selectedBusinessEntity &&
                                                            selectedBusinessEntity.Timezone) ||
                                                            "Unknown"}
                                                    </Typography>
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <Box
                                                display={"flex"}
                                                alignItems={"center"}
                                            >
                                                <ArrowDown
                                                    style={{
                                                        color: "#a8a9ac",
                                                        transform: `scaleY(${
                                                            openEntity ? -1 : 1
                                                        })`
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                        </div>
                    </div>
                    <Popover
                        id={"businessEntitySwitcher"}
                        open={openEntity}
                        anchorEl={entityAnchorEl}
                        onClose={() => {
                            setOpenEntity(false);
                        }}
                        style={{ left: 200, top: 0 }}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            className={classes.productSwitcher}
                        >
                            <Grid
                                container
                                wrap="nowrap"
                                style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                <Grid item>
                                    <Box display={"flex"} alignItems={"center"}>
                                        <LanguageIcon
                                            style={{ color: "lightgray" }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body2"
                                        align="left"
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: 500,
                                            color: "gray"
                                        }}
                                    >
                                        {props.currentOrganization.name}(site)
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            <List
                                component="nav"
                                style={{ width: "100%", padding: "0px" }}
                            >
                                {businessEntities &&
                                    businessEntities.map((be) => (
                                        <ListItem button>
                                            <ListItemIcon>
                                                <BusinessIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={be.Name}
                                                className={`${classes.dpSubtitle}`}
                                                onClick={(e) =>
                                                    onEntityClick(
                                                        e,
                                                        be.Business_Entity_Id
                                                    )
                                                }
                                            />
                                        </ListItem>
                                    ))}
                            </List>
                        </Box>
                    </Popover>
                </div>
            )}

            {/* main menu */}
            {orgNav ? (
                <Fade in={orgNav} unmountOnExit={true}>
                    <div className={classes.orgSidebar}>
                        <OrganizationSidenav
                            className="oddEvenList"
                            {...rest}
                        />
                    </div>
                </Fade>
            ) : (
                <MenuSidenavCompact id={"menu"} space={space} />
            )}
            {/* sidenav footer */}
            <Slide direction="up" in={!orgNav} unmountOnExit={true}>
                <AppBar
                    color="default"
                    position="static"
                    className={classes.sideNavFooter}
                >
                    <Toolbar disableGutters>
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            flex={1}
                            justifyContent={"flex-start"}
                            paddingTop={1}
                        >
                            <Box style={{ padding: open ? "0 8px" : 0 }}>
                                <ProductSwitcher
                                    classes={classes}
                                    layout={layout}
                                    currentOrganization={
                                        props.currentOrganization
                                    }
                                    space={space}
                                    setSpace={setSpace}
                                    history={props.history}
                                    acEnabled={acEnabled}
                                    acOrgId={acOrgId}
                                    rrEnabled={rrEnabled}
                                    rrOrgId={rrOrgId}
                                />
                                <HelpMenu
                                    classes={classes}
                                    layout={layout}
                                    currentOrganization={
                                        props.currentOrganization
                                    }
                                />
                                <UserMenu classes={classes} layout={layout} />
                            </Box>
                            <Divider mt={1} className={classes.divider} />
                            <Box
                                width={open ? "auto" : "100%"}
                                justifyContent={
                                    open ? "space-between" : "center"
                                }
                                display={"flex"}
                                paddingLeft={open ? 2.5 : 0}
                                paddingRight={1}
                                alignItems={"center"}
                            >
                                {open && (
                                    <Typography
                                        variant="caption"
                                        color="inherit"
                                        noWrap
                                    >
                                        <small>
                                            &copy; {currentYear} CB REVREC{" "}
                                            {currentServiceProfile
                                                ? `(${currentServiceProfile})`
                                                : ""}
                                        </small>
                                    </Typography>
                                )}
                                <IconButton onClick={props.toggleSidenav}>
                                    {open ? (
                                        <ChevronLeftIcon />
                                    ) : (
                                        <ChevronRightIcon />
                                    )}
                                </IconButton>
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Slide>
        </Drawer>
    );
};

function AppSelectorPopOver(props) {
    const {
        open,
        space,
        onUpdate,
        onClose,
        anchorEl,
        acEnabled,
        acOrgId,
        rrEnabled,
        rrOrgId,
        classes,
        currentOrganization
    } = props;

    const id = open ? "simple-popover" : undefined;

    function handleClick(func) {
        func();
        onClose();
    }

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            style={{
                left: 20,
                top: 20
            }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            transformOrigin={{
                vertical: "bottom",
                horizontal: "left"
            }}
        >
            <Box
                display={"flex"}
                flexDirection={"column"}
                className={classes.productSwitcher}
            >
                <List component="nav" aria-label="main mailbox folders">
                    {rrEnabled && space !== "revrec" && (
                        <ListItem button>
                            <ListItemText
                                primary="RevRec"
                                onClick={() =>
                                    handleClick(() => onUpdate("revrec"))
                                }
                                secondary={
                                    "Automatically achieve GAAP compliant revenue recognition"
                                }
                            />
                        </ListItem>
                    )}
                    {acEnabled && space !== "ac" && (
                        <ListItem button>
                            <ListItemText
                                primary="RevRec Premium"
                                onClick={() =>
                                    handleClick(() => onUpdate("ac"))
                                }
                                secondary={
                                    "Consolidate accounting events into a sub-ledger and integrate with your GL"
                                }
                            />
                        </ListItem>
                    )}
                    {(acEnabled && space !== "ac") ||
                    (rrEnabled && space !== "revrec") ? (
                        <Divider />
                    ) : (
                        ""
                    )}
                    <ListItem
                        button
                        onClick={() =>
                            handleClick(() => {
                                if (
                                    currentOrganization.partner ===
                                        "chargebee" &&
                                    currentOrganization.chargebeeBaseUrl
                                ) {
                                    window.location.href =
                                        currentOrganization.chargebeeBaseUrl;
                                } else
                                    window.location.href =
                                        "https://app.chargebee.com";
                            })
                        }
                    >
                        <ListItemText
                            primary="Billing"
                            secondary={
                                "Manage subscription billing, invoicing and payments"
                            }
                        />
                    </ListItem>
                </List>
            </Box>
        </Popover>
    );
}

function TenantSelectorDropDown(props) {
    const {
        id,
        width,
        classes,
        title,
        hasError,
        isLoading,
        subTitle,
        clickable,
        isResponsive,
        isButton,
        orgNav,
        space,
        setSpace,
        currentOrganization,
        history,
        acEnabled,
        acOrgId,
        rrEnabled,
        rrOrgId
    } = props;

    const isSmallScreen = width === "sm" || width === "xs";
    const showAvatarOnly = isSmallScreen && isResponsive;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    async function handleImgClick(event) {
        window.location = "https://app.chargebee.com/login";
    }

    const handleClick = (event) => {
        setAnchorEl(open ? null : event.currentTarget);
        orgNav(!open);
    };

    return (
        <div
            className={classes.dropDownMenuPrimary}
            onClick={props.onClick && !isLoading ? props.onClick : () => {}}
        >
            <div>
                {hasError && (
                    <WarningIcon
                        className={classes.dpIcon}
                        style={{
                            color: yellow[800],
                            width: "25px"
                        }}
                    />
                )}

                <div>
                    <Box classes={{ root: classes.appButtonOverrides }}>
                        <img
                            className={classes.logo}
                            src={space === "revrec" ? rrLogo : actgLogo}
                            onClick={handleImgClick}
                        />
                    </Box>
                </div>

                {/* Organisation Button */}

                <div className={classes.orgSwitcher} onClick={handleClick}>
                    <Paper
                        sx={{
                            my: 1,
                            mx: "auto",
                            p: 2
                        }}
                    >
                        <Grid container wrap="nowrap">
                            <Grid item xs className={classes.ptb0}>
                                <div
                                    className={classes.dpTextContainer}
                                    style={{ textAlign: "left" }}
                                >
                                    <Tooltip title={title}>
                                        <Typography
                                            variant="body2"
                                            className={`${classes.orgTitle} ${classes.orgTitleSidenav}`}
                                            align="left"
                                        >
                                            {title || "N/A"}
                                        </Typography>
                                    </Tooltip>
                                    {subTitle && (
                                        <Tooltip title={subTitle}>
                                            <Typography
                                                variant="body2"
                                                className={`${classes.dpSubtitle} ${classes.orgTitleSidenav}`}
                                                align="left"
                                            >
                                                {subTitle}
                                            </Typography>
                                        </Tooltip>
                                    )}
                                </div>
                            </Grid>
                            <Grid item>
                                {isLoading && (
                                    <CircularProgress
                                        size={20}
                                        color={"primary"}
                                    />
                                )}
                                {clickable &&
                                    !isLoading &&
                                    !showAvatarOnly &&
                                    !isButton && (
                                        <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                        >
                                            <ArrowDown
                                                style={{
                                                    color: "#a8a9ac",
                                                    transform: `scaleY(${
                                                        open ? -1 : 1
                                                    })`
                                                }}
                                            />
                                        </Box>
                                    )}
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const orgConfigs = orgConfig(state);
    const siteTypeConfig =
        orgConfigs && orgConfigs.find((oc) => oc.id == "site/type");
    const acEnabledConfig =
        orgConfigs && orgConfigs.find((oc) => oc.id == "ac/enabled");
    const acOrgIdConfig =
        orgConfigs && orgConfigs.find((oc) => oc.id == "ac/orgId");
    const rrEnabledConfig =
        orgConfigs && orgConfigs.find((oc) => oc.id == "revrec/enabled");
    const rrOrgIdConfig =
        orgConfigs && orgConfigs.find((oc) => oc.id == "revrec/orgId");
    const acSegregateByBusinessEntityConfig =
        orgConfigs &&
        orgConfigs.find((oc) => oc.id == "ac/segregateByBusinessEntity");
    const siteType = siteTypeConfig && siteTypeConfig.value;
    const acEnabled = (acEnabledConfig && acEnabledConfig.value) || false;
    const acOrgId = acOrgIdConfig && acOrgIdConfig.value;
    const rrEnabled = rrEnabledConfig ? rrEnabledConfig.value : true;
    const rrOrgId = rrOrgIdConfig && rrOrgIdConfig.value;
    const acSegregateByBusinessEntity =
        (acSegregateByBusinessEntityConfig &&
            acSegregateByBusinessEntityConfig.value) ||
        false;

    return {
        theme: state.theme,
        siteType,
        acEnabled,
        acOrgId,
        rrEnabled,
        rrOrgId,
        acSegregateByBusinessEntity,
        layout: {
            sidenavOpen: state.layout.sidenavOpen
        },
        loadingOrg: loadingOrg(state)
    };
}

Sidenav.propTypes = {
    toggleSidenav: PropTypes.func.isRequired
};

const styles = (theme) => ({
    appButtonOverrides: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: theme.palette.background.button.main,
        borderRadius: 8,
        height: 53,
        width: "100%",
        marginBottom: theme.spacing(1)
    },

    buttonClosedRootOverrides: {
        backgroundColor: theme.palette.background.button.main,
        justifyContent: "space-between",
        padding: 6,
        height: 53,
        borderRadius: 8,
        color: "white",
        width: "100%",
        "&:hover": {
            background: theme.palette.background.button.main
        },
        marginBottom: 20
    },
    buttonOpenRootOverrides: {
        backgroundColor: theme.palette.background.button.main,
        justifyContent: "space-between",
        padding: 12,
        borderRadius: 0,
        color: "white",
        width: "100%",
        "&:hover": {
            background: theme.palette.background.button.main
        }
    },
    menuItemTitle: {
        fontSize: "0.9rem",
        paddingRight: "12px",
        color: "rgb(77, 82, 102)"
    },
    orgSidebar: {
        backgroundColor: "white",
        overflow: "auto",
        height: "100%",

        "& .MuiListItem-container": {
            "&:nth-child(even)": {
                background: "#f8f8f8"
            },
            "&:hover": {
                background: "#eeeeee"
            }
        }
    },
    divider: {
        marginTop: 8
    },
    toolBarGutterOverrides: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        transition: theme.transitions.create("padding", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    toolBarGutterOverridesOpen: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: -1,
        transition: theme.transitions.create("padding", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    entityToolBarGutterOverrides: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        transition: theme.transitions.create("padding", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerOverride: {
        borderTopRightRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
        backgroundColor: theme.palette.background.default
    },
    sideNav: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap"
    },
    sideNavOpen: {
        width: 260,
        overflow: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        borderTopRightRadius: theme.spacing(0),
        borderTopLeftRadius: theme.spacing(0),
        boxShadow: `1px 0 2px 0 rgba(0,0,40,.08), 6px 0 10px 0 rgba(0,0,40,.08)`
    },
    sideNavDocked: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(7.2) + 1
        },
        color: "#fff",
        overflow: "hidden",
        background: theme.palette.primary.main,
        boxShadow: `1px 0 2px 0 rgba(0,0,40,.08), 6px 0 10px 0 rgba(0,0,40,.08)`,
        "& header": {
            backgroundColor: theme.palette.primary.main,
            "& .MuiPaper-root": {
                "& .MuiToolbar-root": {
                    "& .MuiListItem-button": {
                        paddingLeft: "9px",
                        width: "5px!important",
                        "& .MuiListItemIcon-root": {
                            background: "transparent",
                            minWidth: "24px",
                            padding: "8px",
                            marginRight: "15px",
                            borderRadius: "5px",
                            "&:hover": {
                                backgroundColor: theme.palette.primary[300]
                            }
                        }
                    },
                    "& .MuiIconButton-root": {
                        "& svg": {
                            fill: "#fff"
                        },
                        "&:hover": {
                            color: theme.palette.background.neutral[200]
                            // backgroundColor: `${theme.palette.primary[300]} !important`
                        }
                    },
                    "& .MuiToolbar-root": {
                        "& .MuiBox-root": {
                            width: "58px",
                            background: theme.palette.primary.main
                        }
                    },
                    background: theme.palette.primary.main,
                    overflow: "hidden"
                }
            }
        },
        "& .MuiSvgIcon-root": {
            fill: "#eaeaf4",
            opacity: 0.6
        },

        "& .MuiListItemIcon-root": {
            marginRight: "40px"
        },

        "& .setMenuActive": {
            fontWeight: 600,
            color: theme.palette.primary.main,
            backgroundColor: "rgb(234, 234, 244)",
            "& .MuiSvgIcon-root": {
                fill: theme.palette.background.neutral[600]
            }
        },
        "& .MuiListItem-button": {
            "&:hover": {
                background: theme.palette.primary[300],
                borderRadius: "5px",
                "& .MuiSvgIcon-root": {
                    fill: "#ffffff",
                    opacity: 1
                },
                "& .MuiTypography-body1": {
                    color: "#ffffff"
                }
            }
            // backgroundColor: '#782ca8',
            // borderRadius: '5px'
        },
        "& #menu": {
            "&>div:first-child": {
                overflowX: "hidden"
            }
        },
        "& .MuiBox-root": {
            overflowX: "hidden",
            "& #my-account": {
                paddingLeft: 13,
                paddingRight: 10,
                width: "50px !important",
                marginLeft: 4
            },
            "& #help": {
                paddingLeft: "3px",
                paddingRight: "6px",
                "& .MuiButtonBase-root": {
                    paddingLeft: "13px"
                }
            },
            "& #productSwitcher": {
                paddingLeft: "3px",
                paddingRight: "6px",
                "& .MuiButtonBase-root": {
                    paddingLeft: "13px"
                }
            }
        }
    },
    dropDownMenuPrimary: {
        width: "100%",
        cursor: "pointer",
        "&:hover": {}
    },
    dpIcon: {
        width: "1em !important",
        height: "1em !important"
    },
    dpTextContainer: {
        display: "flex",
        flexDirection: "column"
    },
    logo: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        height: "27px"
    },
    orgTitleSidenav: {
        whiteSpace: "nowrap",
        overflow: "hidden !important",
        textOverflow: "ellipsis",
        textTransform: "none",
        width: "145px",
        paddingRight: 16,
        fontWeight: 600
    },
    orgTitle: {
        color: "inherit",
        fontSize: 14,
        fontWeight: 500
    },
    dpSubtitle: {
        color: theme.palette.background.neutral[500],
        fontSize: 12,
        textTransform: "capitalize"
    },
    avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    impersonation: {
        display: "flex"
    },
    listItemText: {
        flex: "1 1 100%",
        textTransform: "none",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1.75,
        color: theme.palette.background.neutral[600],
        "&hover": {
            color: theme.palette.background.neutral[600],
            fontWeight: 600
        }
    },
    listIcon: {
        height: "24px",
        color: theme.palette.background.neutral[600],
        "&hover": {
            color: theme.palette.background.neutral[600]
        }
    },
    listItemIconOverrides: {
        color: theme.palette.background.neutral[600],
        minWidth: theme.spacing(4),
        height: theme.spacing(3)
    },
    bottomListItemIconOverrides: {
        justifyContent: "center",
        color: theme.palette.background.neutral[600],
        minWidth: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: theme.spacing(2)
    },
    listItemGuttersOverrides: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    listItemRootOverrides: {
        padding: 0,
        borderRadius: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        marginLeft: theme.spacing(1),
        paddingTop: 1,
        paddingBottom: 3
    },
    bottomListItemRootOverrides: {
        borderRadius: theme.spacing(1),
        padding: "1px 8px"
    },
    popover: {
        marginLeft: theme.spacing(-1)
    },
    ptb0: {
        paddingTop: 0,
        paddingBottom: 0
    },
    orgSwitcher: {
        marginBottom: 15
    },
    activeChip: {
        height: "22px",
        fontWeight: 500,
        fontSize: "12px",
        borderRadius: "4px",
        // borderColor: theme.palette.background.green[600],
        color: theme.palette.background.green[600],
        backgroundColor: theme.palette.background.green[100]
    },
    errorChip: {
        height: "22px",
        fontWeight: 500,
        fontSize: "12px",
        borderRadius: "4px",
        // borderColor: theme.palette.background.red[600],
        color: theme.palette.background.red[600],
        backgroundColor: theme.palette.background.red[100]
    },
    warningChip: {
        height: "22px",
        fontWeight: 500,
        fontSize: "12px",
        borderRadius: "4px",
        // borderColor: theme.palette.background.orange[600],
        color: theme.palette.background.orange[600],
        backgroundColor: theme.palette.background.orange[100]
    },
    icon: {
        color: "#757575",
        marginRight: "-27px"
    },
    helpBox: {
        backgroundColor: "white",
        color: "#4d5266",
        cursor: "pointer",
        opacity: "1"
    },
    productSwitcher: {
        backgroundColor: "white",
        color: "#4d5266",
        cursor: "pointer",
        opacity: "1"
    }
});

export default compose(
    withRouter,
    withCookies,
    connect(mapStateToProps, {
        toggleSidenav,
        toggleOrganizations
    }),
    withStyles(styles, { withTheme: true })
)(Sidenav);
