import cloneDeep from "lodash.clonedeep";
import shortid from "shortid";
import { AccountingUtils } from "revlock-accounting";
import { Utils } from "revlock-webutils";

function childProducts(products, parent, productsByParent) {
    if (!parent) return [];

    return productsByParent[parent.code] || [];
}

function getProduct(salesOrderItem) {
    return salesOrderItem.product;
}

function isCreditNote(salesOrderItem) {
    const { invoiceNumber, referenceInvoiceNumber } = salesOrderItem;
    return (
        invoiceNumber &&
        referenceInvoiceNumber &&
        invoiceNumber !== referenceInvoiceNumber
    );
}

function getCustomFieldsName(customFieldAttribute, quantity) {
    if (customFieldAttribute && quantity) {
        for (let customField of Object.values(customFieldAttribute)) {
            if (customField.displayName === quantity) {
                return customField.name;
            }
        }
    }
    return undefined;
}

function copyFields(source, dest, fields) {
    fields.forEach((field) => {
        if (source[field]) {
            dest[field] = source[field];
        }
    });
}

export const preprocess = (originalSalesOrder, context) => {
    let {
        referenceData: { products, productsByParent, orgConfig, organization }
    } = context;
    // performance optimization using product by parent
    const createSinglePoItem =
        orgConfig.find((cfg) => cfg.id == "properties/create-single-po-item")
            ?.value || false;
    const { contractId } = originalSalesOrder;
    // when organization partner is chargebee and the current salesorder is
    // not a contract term then don't infer the order.
    // check REV-10440
    if (
        createSinglePoItem &&
        organization &&
        organization.partner == "chargebee" &&
        !contractId
    ) {
        return originalSalesOrder;
    }

    const reasonCodeConfig =
        orgConfig &&
        orgConfig.find((cfg) => cfg.id == "properties/expense/reasoncodes");
    const reasonCodeValues = (reasonCodeConfig && reasonCodeConfig.value) || [];

    const customFieldAttributeConfig =
        orgConfig &&
        orgConfig.find((cfg) => cfg.id == "customFields/attributeMapping");
    const customFieldAttribute =
        (customFieldAttributeConfig && customFieldAttributeConfig.value) || [];

    const listPriceCalcConfig =
        orgConfig &&
        orgConfig.find((cfg) => cfg.id == "properties/enable_list_price_calc");
    const listPriceCalc =
        (listPriceCalcConfig && listPriceCalcConfig.value) || false;

    const deferredByItemConfig =
        orgConfig &&
        orgConfig.find((cfg) => cfg.id == "properties/deferred_by_item");
    const deferredByItem =
        (deferredByItemConfig && deferredByItemConfig.value) || false;

    const billingAdjustmentConfig =
        orgConfig &&
        orgConfig.find(
            (cfg) =>
                cfg.id == "properties/deferred_revenue_by_revneue_allocation"
        );
    const billingAdjustmentEnabled =
        (billingAdjustmentConfig && billingAdjustmentConfig.value) || false;

    if (!productsByParent) {
        productsByParent = new Map();
        products.forEach((product) => {
            if (product.parent && product.entityType === "PO") {
                if (!productsByParent[product.parent]) {
                    productsByParent[product.parent] = [];
                }
                productsByParent[product.parent].push(product);
            }
        });
    }

    if (!originalSalesOrder) return;

    const salesOrder = cloneDeep(originalSalesOrder);

    let salesOrderItems = cloneDeep(salesOrder.salesOrderItem);
    const poProductsProcessed = new Set();
    const poProcessedSalesOrderItemIds = [];

    const baseSalesOrderItems = salesOrderItems.filter((item) => {
        const product = getProduct(item);
        if (product && product.entityType === "BASE") {
            return item;
        }
    });

    const poSalesOrderItems = salesOrderItems.filter((item) => {
        const product = getProduct(item);
        if (product && product.entityType === "PO") {
            return item;
        }
    });

    let newSalesOrderItems = [];

    for (let salesOrderItem of salesOrderItems) {
        const product = getProduct(salesOrderItem);

        //only process base products..
        const poProducts = childProducts(products, product, productsByParent);

        if (product && poProducts.length) {
            for (let poProduct of poProducts) {
                // Allow one entry per PO product
                if (
                    createSinglePoItem &&
                    poProductsProcessed.has(poProduct.id)
                ) {
                    continue;
                }

                const { quantity = 1 } = poProduct;

                // itemToPush may already exist becuase of preprocess done in prior upload
                // if NOT FOUND then only create new item to push.
                let poItem = poSalesOrderItems.find(
                    (item) =>
                        item.productId == poProduct.id &&
                        item.parentSalesOrderItemId == salesOrderItem.id
                );

                let itemToPush = poItem || {
                    id: shortid.generate(),
                    // multiple line items, so different external id.
                    externalId: createSinglePoItem
                        ? poProduct.code
                        : `${salesOrderItem.id}-${poProduct.code}`,
                    salesOrderId: originalSalesOrder.id,
                    productId: poProduct.id,
                    quantity: 1,
                    salePrice: 0.0,
                    listPrice: listPriceCalc
                        ? AccountingUtils.computeListPrice(
                              poProduct,
                              salesOrderItem.startDate || salesOrder.orderDate,
                              salesOrderItem.endDate
                          )
                        : 0.0,
                    product: poProduct,
                    // this is to identify the parent of the current po line item
                    parentSalesOrderItemId: salesOrderItem.id
                };

                if (salesOrder.isTerminated) {
                    itemToPush.endDate = salesOrderItem.endDate;
                    itemToPush.dereferencedEndDate =
                        salesOrderItem.dereferencedEndDate;
                    itemToPush.userSelectedEndDate =
                        salesOrderItem.userSelectedEndDate;
                }

                if (listPriceCalc) {
                    itemToPush.isListPriceProRated = true;
                }

                if (!createSinglePoItem) {
                    if (isCreditNote(salesOrderItem)) {
                        itemToPush.isCreditNote = true;
                    }
                    const fields = [
                        "referenceLineItemId",
                        "invoiceNumber",
                        "referenceInvoiceNumber",
                        "subscriptionId"
                    ];
                    if (deferredByItem && billingAdjustmentEnabled) {
                        copyFields(salesOrderItem, itemToPush, fields);
                    }
                }

                // TODO: always do this even if you you are using exsitng itemToPush or you have new item, because
                // the salesOrderItem.attribue may have updates.
                if (reasonCodeValues.length) {
                    // po item should have all it's parent attributes too.
                    itemToPush["attributes"] = Object.assign(
                        {},
                        poProduct.attributes,
                        salesOrderItem.attributes
                    );
                } else {
                    itemToPush["attributes"] = poProduct.attributes;
                }

                if (quantity === "Accumulate Quantity") {
                    itemToPush["quantity"] = 0;
                    // credit note should not be accumulated in quantity
                    for (let baseSalesOrderItem of baseSalesOrderItems) {
                        const {
                            referenceInvoiceNumber,
                            invoiceNumber
                        } = baseSalesOrderItem;
                        // reference invoice number is not equal to invoice number that means it is credit-note
                        if (
                            baseSalesOrderItem.product.id == product.id &&
                            referenceInvoiceNumber == invoiceNumber
                        ) {
                            itemToPush["quantity"] +=
                                baseSalesOrderItem.quantity;
                        }
                    }
                } else if (Utils.isNumeric(quantity)) {
                    itemToPush["quantity"] = Utils.isNumeric(quantity)
                        ? parseInt(quantity)
                        : 1;
                } else {
                    let customFieldName = getCustomFieldsName(
                        customFieldAttribute,
                        quantity
                    );
                    if (customFieldName) {
                        let quantityFromCustomField =
                            salesOrder.orderAttributes &&
                            salesOrder.orderAttributes[customFieldName];

                        if (
                            !quantityFromCustomField &&
                            salesOrderItem.attributes &&
                            salesOrderItem.attributes[customFieldName]
                        ) {
                            quantityFromCustomField =
                                salesOrderItem.attributes[customFieldName];
                        }

                        if (
                            !quantityFromCustomField &&
                            poProduct.attributes &&
                            poProduct.attributes[customFieldName]
                        ) {
                            quantityFromCustomField =
                                poProduct.attributes[customFieldName];
                        }

                        itemToPush["quantity"] = Utils.isNumeric(
                            quantityFromCustomField
                        )
                            ? parseInt(quantityFromCustomField)
                            : 1;
                    } else {
                        itemToPush["quantity"] = 1;
                    }
                }

                if (poItem) {
                    const index = salesOrder.salesOrderItem.findIndex(
                        (item) => item.id === itemToPush["id"]
                    );
                    salesOrder.salesOrderItem[index] = itemToPush;
                } else {
                    newSalesOrderItems.push(itemToPush);
                }

                poProductsProcessed.add(poProduct.id);
            }

            salesOrderItem.poProcessed = true;
            // this is to populate poProcessed attribute at original line item level
            poProcessedSalesOrderItemIds.push(salesOrderItem.id);
        }
    }

    salesOrder.salesOrderItem.push(...newSalesOrderItems);

    if (poProcessedSalesOrderItemIds.length) {
        salesOrder.salesOrderItem.forEach((item) => {
            if (poProcessedSalesOrderItemIds.includes(item.id)) {
                item.poProcessed = true;
            }
        });
    }

    // context.referenceData["productsByParent"] = productsByParent;

    return salesOrder;
};
