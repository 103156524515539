export class ConfigProvider {
    private configList: any[];
    private configMap: Map<string, any> = new Map();

    constructor(configList: any[]) {
        this.configList = configList;

        for (let config of this.configList) {
            this.configMap.set(config.id, config);
        }
    }

    public useWhDataForReversalsConfig(): boolean {
        const useWhDataForReversalsConfig = this.configMap.get(
            "properties/use_warehouse_data_for_reversal"
        );
        return (
            (useWhDataForReversalsConfig &&
                useWhDataForReversalsConfig.value) ||
            false
        );
    }

    public calendarConfig(): any {
        const d = this.configMap.get("properties/calendar");
        return d && d.value && d.value.config;
    }

    public unearnedByValueConfig(): string {
        const unearnedByConfig = this.configMap.get("properties/unearned_by");
        return (unearnedByConfig && unearnedByConfig.value) || "customer";
    }

    public recognizeByTransactionDateConfig(): boolean {
        const recognizeByTransactionDateConfig = this.configMap.get(
            "properties/recognize-by-transaction-date"
        );
        return (
            recognizeByTransactionDateConfig &&
            recognizeByTransactionDateConfig.value
        );
    }

    public psdByProductCodeConfig(): boolean {
        const psdByProductCodeConfig = this.configMap.get(
            "properties/service-delivery-by-product-code"
        );
        return psdByProductCodeConfig && psdByProductCodeConfig.value;
    }

    public endDateInclusiveConfig(): boolean {
        const endDateInclusiveConfig = this.configMap.get(
            "properties/ratable_end_date_inclusive"
        );
        return endDateInclusiveConfig && endDateInclusiveConfig.value;
    }

    public disableCreditNoteAllocationConfig(): boolean {
        const disableCnAllocationConfig = this.configMap.get(
            "properties/contract-term/disable-cn-allocation"
        );
        return disableCnAllocationConfig && disableCnAllocationConfig.value;
    }

    public autoCreateSSP(): boolean {
        const autoCreateSSPConfig = this.configMap.get(
            "properties/auto-create-ssp"
        );
        return (autoCreateSSPConfig && autoCreateSSPConfig.value) || false;
    }

    public sspFieldConfig(): any[] {
        let sspFieldsConfig = this.configMap.get("properties/ssp-fields");
        return (sspFieldsConfig && sspFieldsConfig.value) || [];
    }

    public isMeteredEnabled(): boolean {
        let meteredCfg = this.configMap.get(
            "properties/metered_connection_enabled"
        );
        return (meteredCfg && meteredCfg.value) || false;
    }

    public customFieldsConfig(): any {
        const cfConfig = this.configMap.get("customFields/attributeMapping");
        return cfConfig && cfConfig.value;
    }

    public customFieldsValues(): any {
        const cfConfig = this.customFieldsConfig();
        return cfConfig && Object.values(cfConfig);
    }

    public maxContractYearsConfig(): number {
        const d3 = this.configMap.get("properties/max-contract-years");
        return (d3 && d3.value && Number(d3.value)) || 15;
    }

    public dereferenceRuleHasPriority(): boolean {
        const d = this.configMap.get(
            "properties/dereference_rule_has_priority"
        );
        return (d && d.value) || false;
    }

    public dereferenceRuleHasPrioryDisableProduct(): boolean {
        const d = this.configMap.get(
            "properties/dereference_rule_has_priority/disable-product"
        );
        return (d && d.value) || false;
    }

    public useProduceListPriceConfig(): boolean {
        const useProductListPriceConfig = this.configMap.get(
            "properties/enable_list_price_calc"
        );
        return (
            (useProductListPriceConfig && useProductListPriceConfig.value) ||
            false
        );
    }

    public expenseReasonCode(): any[] {
        const reasonCodeConfig = this.configMap.get(
            "properties/expense/reasoncodes"
        );
        return (reasonCodeConfig && reasonCodeConfig.value) || [];
    }

    public cnAllocationByBacklog(): any[] {
        const config = this.configMap.get(
            "properties/revenue/credit-note/by-backlog"
        );
        return (config && config.value) || false;
    }
}
