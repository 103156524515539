import RecognitionRule from "./reference/RecognitionRule";
import { updateRatablePlan } from "./plan/ApportionedRatablePlan";
import ItemType from "./reference/ItemType";
import { Period } from "revlock-webutils";

/**
 * Recognition Method - Ratable
 * 1. If revenue is fully recognized before the pauseRevenueDate, nothing to update here
 * 2. If revenue is 0 recognized before the pauseRevenueDate, update the pausedRevenue to be 0
 * 3. Else, Identify the affected accounting period and update the revenue as 0 after the pauseRevenueDate
 */
const ratableItemPauseRevenue = ({
    pauseRevenueDate,
    salesOrder,
    revenueArrangementItem,
    endDateInclusive
}) => {
    revenueArrangementItem.pauseRevenueDate = pauseRevenueDate;
    // Change item delivery end date to termination date regardless.

    if (revenueArrangementItem.productRevenue == 0) {
        updateRatablePlan(revenueArrangementItem.plan, 0);
        return;
    } // No revenue to be recognized on this item.

    // Check if the item is fully recognized, paused revenue equals actual allocation revenue
    const fullyRecognized = endDateInclusive
        ? Period.isSameOrAfter(
              pauseRevenueDate,
              revenueArrangementItem.deliveryEndDate
          )
        : Period.isAfter(
              pauseRevenueDate,
              revenueArrangementItem.deliveryEndDate
          );
    if (fullyRecognized) {
        return;
    }

    // If item service start date is in the future, paused revenue equals zero.
    const zeroRecognized = Period.isAfter(
        revenueArrangementItem.deliveryStartDate,
        pauseRevenueDate
    );
    if (zeroRecognized) {
        salesOrder.isRevenuePaused = 1;
        revenueArrangementItem.pausedProductRevenue = 0;
        updateRatablePlan(revenueArrangementItem.plan, 0);
        return;
    }

    const newPlan = [];
    let pausedProductRevenue = 0;

    let revenuePaused = false;

    // Now let find prorate revenue allocation.
    for (let index = 0; index < revenueArrangementItem.plan.length; index++) {
        const element = revenueArrangementItem.plan[index];

        if (revenuePaused) {
            // already revenue is paused, update plan element to recognize no revenue.
            element.planAmount = 0;
            newPlan.push(Object.assign({}, element));
            continue;
        }

        let { actgPeriod } = element;

        revenuePaused = Period.isDateBetween(
            pauseRevenueDate,
            actgPeriod.startDate,
            actgPeriod.endDate,
            true,
            true
        );
        // This is the period when we terminate the arrangement and contract.
        if (revenuePaused) {
            let start = actgPeriod.startDate;

            if (index === 0) {
                start = revenueArrangementItem.deliveryStartDate;
            } // revenue paused in first month, startdate is first day of service

            let end = actgPeriod.endDate;

            if (index === revenueArrangementItem.plan.length - 1) {
                end = revenueArrangementItem.deliveryEndDate;

                if (!endDateInclusive) {
                    end = Period.addDays(end, -1);
                    // convert end to inclusive date as it's not.
                }
            } // Revenue paused in last month, enddate is last day of service

            const periodDays = Period.daysBetween(start, end, true, true);
            // Original Plan: total period days of service in this month.

            const pausedPeriodDays = Period.daysBetween(
                start,
                pauseRevenueDate,
                true,
                true
            );
            // Revenue paused Plan: total period days of service in this month.
            if (periodDays > 0) {
                // Now let's prorate this month.
                element.planAmount =
                    (element.planAmount * pausedPeriodDays) / periodDays;
            }
        }

        newPlan.push(Object.assign({}, element));
        pausedProductRevenue += element.planAmount;
    }

    salesOrder.isRevenuePaused = 1;
    revenueArrangementItem.plan = newPlan;
    revenueArrangementItem.pausedProductRevenue = pausedProductRevenue;
    // Preserve all the hard work that we just did.
};

/**
 * Recognition Method: Point In Time
 * If revenue is recognized before the pauseRevenueDate, nothing to update here
 * Else, update the revenue for the item as 0
 */
const pointInTimeItemPauseRevenue = ({
    pauseRevenueDate,
    salesOrder,
    revenueArrangementItem,
    endDateInclusive
}) => {
    revenueArrangementItem.pauseRevenueDate = pauseRevenueDate;

    let deliveryDate = revenueArrangementItem.deliveryStartDate;
    if (revenueArrangementItem.ssp.recognizedOn === "endDate") {
        deliveryDate = revenueArrangementItem.deliveryEndDate;

        if (!endDateInclusive) {
            deliveryDate = Period.addDays(end, -1);
            // convert end to insclusive date as it's not.
        }
    }

    const recognized = Period.isSameOrAfter(pauseRevenueDate, deliveryDate);

    if (!recognized) {
        salesOrder.isRevenuePaused = 1;
        revenueArrangementItem.pausedProductRevenue = 0;
        updateRatablePlan(revenueArrangementItem.plan, 0);
    }
};

const PAUSE_REVENUE_RULES = {
    [RecognitionRule.POINT_IN_TIME]: pointInTimeItemPauseRevenue,
    [RecognitionRule.RATABLE]: ratableItemPauseRevenue
};

const getRevenueArrangementItemBySalesOrderItem = (
    revenueArrangement,
    salesOrderItem
) => {
    return (
        revenueArrangement &&
        revenueArrangement.revenueArrangementItem.find((rai) => {
            let itemId = rai.salesOrderItem.rootId || rai.salesOrderItem.id;
            const _itemId = salesOrderItem.rootId || salesOrderItem.id;
            if (itemId === _itemId && !ItemType.isFrozen(rai.itemType)) {
                return rai;
            }
        })
    );
};

const applyPauseRevenueRule = (params) => {
    const { salesOrder, salesOrderItem, revenueArrangement, logger } = params;

    const revenueArrangementItem = getRevenueArrangementItemBySalesOrderItem(
        revenueArrangement,
        salesOrderItem
    );

    if (!revenueArrangementItem) {
        logger.warn(
            `Revenue arrangement item not found for sales order ${salesOrder.id} and sales order item ${salesOrderItem.id} (product ${salesOrderItem.productId})`
        );
        return;
    }

    const recognitionRule =
        revenueArrangementItem && revenueArrangementItem.recognitionRule.id;

    PAUSE_REVENUE_RULES[recognitionRule]({
        ...params,
        revenueArrangement,
        revenueArrangementItem
    });
};

export const pauseRevenue = (
    salesOrder,
    pauseRevenueDate,
    orgConfig,
    logger
) => {
    const d1 = orgConfig.find((config) => config.id === "properties/calendar");
    const calendarConfig = d1 && d1.value && d1.value.config;

    const d2 = orgConfig.find(
        (config) => config.id == "properties/ratable_end_date_inclusive"
    );
    const endDateInclusive = (d2 && d2.value) || false;

    if (salesOrder.newRevenueArrangement) {
        for (let salesOrderItem of salesOrder.salesOrderItem) {
            applyPauseRevenueRule({
                salesOrder,
                revenueArrangement: salesOrder.newRevenueArrangement,
                salesOrderItem,
                pauseRevenueDate,
                calendarConfig,
                endDateInclusive,
                orgConfig,
                logger
            });
        }
        if (salesOrder.isRevenuePaused) {
            salesOrder.newRevenueArrangement.isRevenuePaused = true;
        }
    }
};
