import React from "react";
import { connect } from "react-redux";
import { compose, showAlert } from "utils/WebUtils";
import clsx from "classnames";
import {
    triggerAcTour,
    currentOrganization,
    currentUser,
    getClientTablesConfig,
    getOrgConfigurations,
    sideNavOpen
} from "redux/selectors";
import {
    Avatar,
    Button,
    List,
    ListItem,
    Toolbar,
    Tooltip
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { SecurityUtils } from "revlock-webutils";
import swal from "sweetalert";

import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { Lock as LockIcon } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { contactSales } from "rest/API";

import { NavLink, withRouter } from "react-router-dom";

import { getMenuItems } from "../../../config";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import logoImage from "assets/images/chargebee-icon-orange.png";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import { licensedExecute } from "utils/auth";
import { markSeenACTour } from "utils/Crisp";

import { setData2 } from "redux/actions";
import {
    GolfCourse as StartedIcon,
    ArrowForward as ForwardIcon
} from "@material-ui/icons";
import JobProgress from "components/JobProgress";
const styles = (theme) => ({
    mobilelogo: {
        display: "none"
    },
    mobileshowlogo: {
        display: "block",
        paddingLeft: "0",
        paddingRight: "0",
        minHeight: "35px",
        "& .MuiAvatar-root": {
            padding: "0",
            marginTop: "15px",
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    sideNavDockedOverride: {
        backgroundColor: theme.palette.background.dockedMenu,
        "& .MuiListItemText-root": {
            color: "#ffffff"
        },
        "& .MuiListItem-root": {
            "&:hover": {
                backgroundColor: `${theme.palette.background.primary[600]} !important`,
                minWidth: "35px"
            }
        },
        "& .MuiListItemIcon-root": {
            paddingRight: "0px",
            width: "35px"
        },
        "& .MuiButtonBase-root": {
            width: "35px",
            padding: "3px 0px"
        },
        "& .submenuwindow": {
            "& .MuiListItemIcon-root": {
                marginRight: "0px"
            },
            "& .MuiListItem-root": {
                paddingTop: "2px",
                paddingBottom: "2px",
                paddingLeft: "4px",
                background: "transparent",
                width: "100%",
                "& .MuiSvgIcon-root": {
                    fill: "#eaeaf4"
                },
                "&:hover": {
                    width: "100%"
                }
            },
            "& .MuiListItemText-root": {
                "& .MuiTypography-body1": {
                    fontWeight: "400"
                }
            }
        }
    },
    contentWrapper: {
        position: "relative",
        overflow: "hidden",
        flex: "1 1 100%",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        height: "100%"
    },
    content: {
        width: "100%",
        minWidth: "100%",
        height: "100%",
        minHeight: "100%",
        margin: "0",
        overflowY: "auto",
        "-ms-overflow-style": "none",
        "&::-webkit-scrollbar": {
            width: "0"
        }
    },

    list: {
        paddingTop: 0,
        paddingBottom: 0
    },
    listItemRoot: {
        // flexDirection: 'column',
        // alignItems: 'start',
        // display: 'flex',
        paddingBottom: 0,
        paddingTop: 0,
        color: "white"
    },
    listItem: {
        textTransform: "none",
        display: "flex",
        paddingTop: 0,
        paddingBottom: 0,
        width: "100%",
        minHeight: 96,
        alignItems: "center",
        boxSizing: "border-box",
        paddingLeft: 16,
        paddingRight: 16
    },
    listItemActive: {
        backgroundColor: theme.palette.background.url,
        fontSize: 14,
        fontWeight: 600,
        color: "#eaeaf4",
        "&hover": {
            backgroundColor: theme.palette.background.url,
            fontWeight: 600
        },
        "& .MuiSvgIcon-root": {
            fill: "black"
        },
        "& .MuiListItemText-root": {
            "& .MuiTypography-body1": {
                color: theme.palette.background.neutral[500]
            }
        }

        // '& svg': {
        //   color: 'black'
        // },
        // '& div': {
        //   color: 'black !important',
        //   fontWeight: 700,
        // },
        // '& p': {
        //   color: 'black !important',
        //   fontWeight: 700,
        // }
    },
    listItemButtonLabel: {
        flexDirection: "column"
    },
    dockedListItemText: {
        color: theme.palette.background.neutral[200],
        flex: "1 1 100%",
        textTransform: "none",
        fontSize: 14,
        lineHeight: 1.75,
        "&hover": {
            color: theme.palette.background.neutral[600],
            fontWeight: 600
        }
    },
    listItemText: {
        color: theme.palette.background.neutral[700],
        flex: "1 1 100%",
        textTransform: "none",
        fontSize: 14,
        lineHeight: 1.75,
        "&hover": {
            color: theme.palette.background.neutral[600],
            fontWeight: 600
        }
    },
    logo: {
        paddingRight: theme.spacing(1),
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3)
    },

    listIcon: {
        "& .MuiSvgIcon-root": {
            width: "18px",
            marginLeft: "0px"
        },
        height: "18px",
        color: theme.palette.background.neutral[600],
        marginLeft: "auto",
        marginRight: "auto",
        "&hover": {
            color: theme.palette.background.neutral[200]
        }
    },
    lockIcon: {
        height: "17px",
        color: theme.palette.background.neutral[600],
        marginTop: "5px"
    },
    getStartedListItemIconOverrides: {
        color: theme.palette.background.button.main
    },
    getStartedListItemTextOverrides: {
        color: theme.palette.background.button.main
    },
    listText: {
        fontSize: "15px"
    },
    item: {
        paddingTop: "3px",
        paddingBottom: "3px"
    },
    listItemIconOverrides: {
        color: theme.palette.background.neutral[600],
        minWidth: theme.spacing(3),
        height: theme.spacing(3),
        paddingRight: 12
    },
    listItemGuttersOverrides: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    listItemRootOverrides: {
        padding: "1px 8px",
        borderRadius: theme.spacing(0.5),
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "2px",
        position: "relative",
        zIndex: "999"
    },
    minIconWidth: {
        minWidth: "32px"
    },
    subItem: {
        color: theme.palette.background.neutral[500],
        fontSize: "0.75rem",
        fontWeight: 400
    },
    divider: {
        width: "100%",
        marginBottom: theme.spacing(1)
    },
    root: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    alert: {
        textWrap: "wrap"
    },
    alertHeading: {
        marginBottom: "6px",
        marginTop: 0
    },
    alertButton: {
        marginTop: "12px"
    }
});

const quickViewMessages = {
    accounting: {
        message:
            `Set up general ledger accounts to customize account mapping, ` +
            `and connect to your accounting system to automatically post journal entries.`,
        link:
            "https://www.chargebee.com/docs/revrec/journal-account-mapping.html"
    },
    sync: {
        message:
            `Sync data automatically or manually between RevRec and your third-party systems. ` +
            `You can sync sales contract data from your CRM, and invoices from your billing software and mobile stores, ` +
            `and post journal entries to your accounting system. `,
        link: "https://www.chargebee.com/docs/revrec/data-overview.html"
    },
    "configure revrec": {
        message:
            `Configure <a href="https://www.chargebee.com/docs/revrec/journal-posting-closing.html" style="color: #2d6a8a;padding-left: 2px;text-decoration-line:none" target="_black"> accounting close</a>, ` +
            `<a href="https://www.chargebee.com/docs/revrec/multi-currency.html" style="color: #2d6a8a;padding-left: 2px;text-decoration-line:none" target="_black">multi-currency</a> and ` +
            `<a href="https://www.chargebee.com/docs/revrec/standalone-selling-price.html" style="color: #2d6a8a;padding-left: 2px;text-decoration-line:none" target="_black">standalone sale price</a>. `
    },
    "accounting rules": {
        message:
            `Configure ` +
            `<a href="https://www.chargebee.com/docs/revrec/configuring-revenue-rules.html" style="color: #2d6a8a;padding-left: 2px;text-decoration-line:none" target="_black">revenue</a> and ` +
            `<a href="https://www.chargebee.com/docs/revrec/expense-recognition.html" style="color: #2d6a8a;padding-left: 2px;text-decoration-line:none" target="_black"> expense recognition rules.</a>`
    }
};

class MenuSidenavCompact extends React.Component {
    constructor(props) {
        super(props);
    }

    handleOnClick = (index) => {
        this.setState({ activeIndex: index });
    };
    state = {
        anchor: null,
        open: null,
        openMenu: {}
    };

    handleClose = () => {
        this.setState({ anchor: null, open: null });
    };

    handleClick = (event, title) => {
        const id = "id-" + this.props.space + "-" + title;
        const newMenu = this.state.openMenu;
        newMenu[id] = !this.state.openMenu[id];
        this.setState({ openMenu: newMenu });
    };

    showGetStarted = () => {
        const { history } = this.props;

        history && history.push("/");

        setData2("showWelcomeTour", true);

    };

    showAcOnboardingTour = () => {
        setData2("currentJoyRide", "AcOnboardingTour");
        setData2("joyRideData",{ run: true, stepIndex: 0 });
        this.setState({ openMenu: {"id-ac-Settings":true} });
    }
 
    callExpert = async () => {
        const { currentOrganization } = this.props;
        const allPlans = SecurityUtils.allPlans();
        const upgradeTo = allPlans[currentOrganization.license.name].nextPlan;

        try {
            await contactSales(currentOrganization.id, "plan", upgradeTo);

            await swal({
                title: `Thank you!!`,
                icon: "success",
                text: `Our RevRec teammate will contact you shortly.`
            });
        } catch (err) {
            await swal({
                title: "Error",
                icon: "error",
                text: `Something didn't quite work ${err.errorMessage}`,
                error: true,
                timer: 2000,
                buttons: false
            });
        }
    };

    componentDidMount = () => {
        const {productSpace, triggerAcTour, history, currentOrganization, isDemoSite} = this.props
        if(window.location.pathname.startsWith('/ac/')  && triggerAcTour && isDemoSite){
            setTimeout(async() => {
                this.showAcOnboardingTour()
                await markSeenACTour(currentOrganization.id, undefined, history)
            }, 5000);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSideNavOpen != this.props.isSideNavOpen) {
            this.setState({ openMenu: {} });
        }
        return null;
    }

    render() {
        const {
            classes,
            currentUser,
            currentOrganization,
            clientTables,
            id,
            isSideNavOpen,
            orgConfig,
            history,
            isDemoSite
        } = this.props;
        const { anchor, open, openMenu } = this.state;
        const menuProps = {
            clientTables,
            currentUser,
            open: isSideNavOpen,
            orgConfig,
            space: this.props.space
        };
        const menuItems = getMenuItems(undefined, menuProps);
        //console.error("SPACE ===> ", this.props.space, menuProps.space);
        const revEssential =
            (currentOrganization &&
                currentOrganization.license &&
                currentOrganization.license.name === "rev-essential") ||
            false;
        const superAdminLevel = SecurityUtils.getSuperAdminLevel(currentUser);
        const disableActionsForQuickView =
            revEssential && superAdminLevel < SecurityUtils.SUPER_ADMIN_LEVEL_1;

        return (
            <div
                id={id}
                className={
                    isSideNavOpen
                        ? classes.contentWrapper
                        : clsx(
                              classes.contentWrapper,
                              classes.sideNavDockedOverride
                          )
                }
            >
                <div className={classes.content}>
                    <Toolbar
                        className={
                            isSideNavOpen
                                ? `${classes.mobilelogo}`
                                : classes.mobileshowlogo
                        }
                    >
                        <a href="https://app.chargebee.com/login">
                            {" "}
                            <Avatar className={classes.logo} src={logoImage} />
                        </a>
                    </Toolbar>
                    <div className={classes.root}>
                        <List component="nav">
                            {(!menuProps.space ||
                                menuProps.space === "revrec") &&
                                !disableActionsForQuickView &&
                                !currentOrganization.id.startsWith("6") &&
                                !currentOrganization.id.startsWith("7") && (
                                    <>
                                        <ListItem
                                            button
                                            exact="true"
                                            onClick={this.showGetStarted}
                                            activeclassname={
                                                classes.listItemActive
                                            }
                                            classes={{
                                                root:
                                                    classes.listItemRootOverrides,
                                                gutters:
                                                    classes.listItemGuttersOverrides
                                            }}
                                            key={"get_started"}
                                        >
                                            <ListItemIcon
                                                classes={{
                                                    root:
                                                        classes.listItemIconOverrides
                                                }}
                                            >
                                                <StartedIcon
                                                    className={
                                                        isSideNavOpen
                                                            ? classes.getStartedListItemIconOverrides
                                                            : classes.listIcon
                                                    }
                                                />
                                            </ListItemIcon>

                                            <ListItemText>
                                                <Typography
                                                    className={
                                                        isSideNavOpen
                                                            ? clsx(
                                                                  classes.listItemText,
                                                                  classes.getStartedListItemTextOverrides
                                                              )
                                                            : classes.listItemText
                                                    }
                                                >
                                                    Get Started
                                                </Typography>
                                            </ListItemText>
                                            <ForwardIcon
                                                fontSize={"small"}
                                                className={
                                                    isSideNavOpen
                                                        ? classes.getStartedListItemIconOverrides
                                                        : classes.listIcon
                                                }
                                            />
                                        </ListItem>
                                    </>
                                )}
                              {(!menuProps.space ||
                                menuProps.space === "ac") &&
                                !disableActionsForQuickView &&
                                !currentOrganization.id.startsWith("6") &&
                                !currentOrganization.id.startsWith("7") && 
                                isDemoSite && (
                                    <>
                                        <ListItem
                                            button
                                            exact="true"
                                            onClick={this.showAcOnboardingTour}
                                            activeclassname={
                                                classes.listItemActive
                                            }
                                            classes={{
                                                root:
                                                    classes.listItemRootOverrides,
                                                gutters:
                                                    classes.listItemGuttersOverrides
                                            }}
                                            key={"get_started"}
                                        >
                                            <ListItemIcon
                                                classes={{
                                                    root:
                                                        classes.listItemIconOverrides
                                                }}
                                            >
                                                <StartedIcon
                                                    className={
                                                        isSideNavOpen
                                                            ? classes.getStartedListItemIconOverrides
                                                            : classes.listIcon
                                                    }
                                                />
                                            </ListItemIcon>

                                            <ListItemText>
                                                <Typography
                                                    className={
                                                        isSideNavOpen
                                                            ? clsx(
                                                                  classes.listItemText,
                                                                  classes.getStartedListItemTextOverrides
                                                              )
                                                            : classes.listItemText
                                                    }
                                                >
                                                    Onboarding Tour
                                                </Typography>
                                            </ListItemText>
                                            <ForwardIcon
                                                fontSize={"small"}
                                                className={
                                                    isSideNavOpen
                                                        ? classes.getStartedListItemIconOverrides
                                                        : classes.listIcon
                                                }
                                            />
                                        </ListItem>
                                    </>
                                )}
                            {menuItems.map((item) => {
                                const isVisible = item.visible(
                                    currentOrganization,
                                    menuProps
                                );

                                if (isVisible)
                                    return item.subItem ? (
                                        <Tooltip
                                            title={
                                                isSideNavOpen ? "" : item.title
                                            }
                                            placement="right"
                                        >
                                            <ListItem
                                                classes={{
                                                    root:
                                                        classes.listItemRootOverrides,
                                                    gutters:
                                                        classes.listItemGuttersOverrides
                                                }}
                                            >
                                                <ListItemText
                                                    disableTypography
                                                    primary={
                                                        <Typography
                                                            type="body2"
                                                            className={
                                                                classes.subItem
                                                            }
                                                        >
                                                            {item.title}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>{" "}
                                        </Tooltip>
                                    ) : item.href ? (
                                        <Tooltip
                                            key={item.title}
                                            title={
                                                isSideNavOpen ? "" : item.title
                                            }
                                            placement="right"
                                        >
                                            <ListItem
                                                id={item.title.toLowerCase()}
                                                button
                                                exact={true}
                                                activeclassname={
                                                    classes.listItemActive
                                                }
                                                className={classes.listItemText}
                                                classes={{
                                                    root:
                                                        classes.listItemRootOverrides,
                                                    gutters:
                                                        classes.listItemGuttersOverrides
                                                }}
                                                component={NavLink}
                                                to={item.href}
                                                onClick={() => {
                                                    if (
                                                        disableActionsForQuickView &&
                                                        [
                                                            "accounting",
                                                            "sync"
                                                        ].includes(
                                                            item.title.toLowerCase()
                                                        )
                                                    ) {
                                                        setData2(
                                                            "showQuickViewBanner",
                                                            "plan"
                                                        );
                                                        setData2(
                                                            "quickViewBanner",
                                                            {
                                                                heading: `Unlock ${item.title}`,
                                                                message: `${
                                                                    quickViewMessages[
                                                                        item.title.toLowerCase()
                                                                    ].message
                                                                }`,
                                                                link: `${
                                                                    quickViewMessages[
                                                                        item.title.toLowerCase()
                                                                    ].link
                                                                }`
                                                            }
                                                        );
                                                    }
                                                }}
                                                key={item.title}
                                            >
                                                <ListItemIcon
                                                    classes={{
                                                        root:
                                                            classes.listItemIconOverrides
                                                    }}
                                                >
                                                    {item.icon && (
                                                        <span
                                                            className={
                                                                classes.listIcon
                                                            }
                                                        >
                                                            {item.icon}
                                                        </span>
                                                    )}
                                                </ListItemIcon>
                                                <ListItemText disableTypography>
                                                    {item.title}
                                                </ListItemText>
                                                {disableActionsForQuickView &&
                                                    [
                                                        "accounting",
                                                        "sync"
                                                    ].includes(
                                                        item.title.toLowerCase()
                                                    ) && (
                                                        <span>
                                                            <LockIcon
                                                                className={
                                                                    classes.lockIcon
                                                                }
                                                            />
                                                        </span>
                                                    )}
                                            </ListItem>
                                        </Tooltip>
                                    ) : (
                                        item.children && (
                                            <div key={item.title}>
                                                <ListItem
                                                    button
                                                    onClick={(e) => {
                                                        this.handleClick(
                                                            e,
                                                            item.title
                                                        );
                                                    }}
                                                    className={
                                                        openMenu[
                                                            "id-" +
                                                                menuProps.space +
                                                                "-" +
                                                                item.title
                                                        ]
                                                            ? `${classes.menuItem} setMenuActive`
                                                            : classes.menuItem
                                                    }
                                                    classes={{
                                                        root:
                                                            classes.listItemRootOverrides,
                                                        gutters:
                                                            classes.listItemGuttersOverrides
                                                    }}
                                                    activeclassname={
                                                        classes.listItemActive
                                                    }
                                                    exact="true"
                                                    onMouseEnter={() => {
                                                        if (!isSideNavOpen) {
                                                            const id =
                                                                "id-" +
                                                                menuProps.space +
                                                                "-" +
                                                                item.title;
                                                            const newMenu = openMenu;
                                                            newMenu[id] = !this
                                                                .state.openMenu[
                                                                id
                                                            ];
                                                            this.setState({
                                                                openMenu: newMenu
                                                            });
                                                        }
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        if (!isSideNavOpen) {
                                                            const id =
                                                                "id-" +
                                                                menuProps.space +
                                                                "-" +
                                                                item.title;
                                                            const newMenu = openMenu;
                                                            newMenu[id] = !this
                                                                .state.openMenu[
                                                                id
                                                            ];
                                                            this.setState({
                                                                openMenu: newMenu
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <ListItemIcon
                                                        classes={{
                                                            root:
                                                                classes.listItemIconOverrides
                                                        }}
                                                    >
                                                        {item.icon && (
                                                            <span
                                                                className={
                                                                    classes.listIcon
                                                                }
                                                            >
                                                                {item.icon}
                                                            </span>
                                                        )}
                                                    </ListItemIcon>

                                                    <ListItemText
                                                        disableTypography
                                                        primary={
                                                            <Typography
                                                                style={{
                                                                    fontWeight: 400
                                                                }}
                                                                type="body2"
                                                                className={
                                                                    classes.listItemText
                                                                }
                                                            >
                                                                {item.title}
                                                            </Typography>
                                                        }
                                                    />
                                                    {openMenu[
                                                        "id-" +
                                                            menuProps.space +
                                                            "-" +
                                                            item.title
                                                    ] ? (
                                                        <IconExpandLess />
                                                    ) : (
                                                        <IconExpandMore />
                                                    )}
                                                </ListItem>
                                                <Collapse
                                                    onMouseEnter={(event) => {
                                                        if (!isSideNavOpen) {
                                                            const id =
                                                                "id-" +
                                                                menuProps.space +
                                                                "-" +
                                                                item.title;
                                                            const newMenu = openMenu;
                                                            newMenu[id] = true;
                                                            this.setState({
                                                                openMenu: newMenu
                                                            });
                                                        }
                                                    }}
                                                    onMouseLeave={(event) => {
                                                        if (!isSideNavOpen) {
                                                            const id =
                                                                "id-" +
                                                                menuProps.space +
                                                                "-" +
                                                                item.title;
                                                            const newMenu = openMenu;
                                                            newMenu[id] = !this
                                                                .state.openMenu[
                                                                id
                                                            ];
                                                            this.setState({
                                                                openMenu: newMenu
                                                            });
                                                        }
                                                    }}
                                                    className={
                                                        !isSideNavOpen
                                                            ? `submenuwindow`
                                                            : ""
                                                    }
                                                    style={
                                                        item.submenuStyles
                                                            ? item.submenuStyles
                                                            : {}
                                                    }
                                                    in={
                                                        openMenu[
                                                            "id-" +
                                                                menuProps.space +
                                                                "-" +
                                                                item.title
                                                        ]
                                                    }
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <Divider />
                                                    <List
                                                        component="nav"
                                                        disablePadding
                                                    >
                                                        {item.children.map(
                                                            (child) => {
                                                                return (
                                                                    <ListItem
                                                                        button
                                                                        id={child.title.toLowerCase()}
                                                                        exact={
                                                                            true
                                                                        }
                                                                        activeclassname={
                                                                            classes.listItemActive
                                                                        }
                                                                        className={
                                                                            classes.item
                                                                        }
                                                                        component={
                                                                            NavLink
                                                                        }
                                                                        to={
                                                                            child.href
                                                                        }
                                                                        key={
                                                                            child.title
                                                                        }
                                                                        onClick={() => {
                                                                            if (
                                                                                disableActionsForQuickView &&
                                                                                [
                                                                                    "configure revrec",
                                                                                    "accounting rules"
                                                                                ].includes(
                                                                                    child.title.toLowerCase()
                                                                                )
                                                                            ) {
                                                                                setData2(
                                                                                    "showQuickViewBanner",
                                                                                    "plan"
                                                                                );
                                                                                setData2(
                                                                                    "quickViewBanner",
                                                                                    {
                                                                                        heading: `Unlock ${child.title}`,
                                                                                        message: `${
                                                                                            quickViewMessages[
                                                                                                child.title.toLowerCase()
                                                                                            ]
                                                                                                .message
                                                                                        }`,
                                                                                        link:
                                                                                            quickViewMessages[
                                                                                                child.title.toLowerCase()
                                                                                            ]
                                                                                                .link
                                                                                    }
                                                                                );
                                                                            } else if (
                                                                                [
                                                                                    "accounting rules"
                                                                                ].includes(
                                                                                    child.title.toLowerCase()
                                                                                )
                                                                            ) {
                                                                                licensedExecute(
                                                                                    {
                                                                                        type:
                                                                                            "plan",
                                                                                        name:
                                                                                            "rev-up"
                                                                                    },
                                                                                    function() {
                                                                                        if (
                                                                                            SecurityUtils.hasRole(
                                                                                                currentOrganization.id,
                                                                                                "Admin",
                                                                                                currentUser
                                                                                            ) ||
                                                                                            SecurityUtils.hasRole(
                                                                                                currentOrganization.id,
                                                                                                "Controller",
                                                                                                currentUser
                                                                                            )
                                                                                        ) {
                                                                                            history.push(
                                                                                                `/${currentOrganization.id}/esp`
                                                                                            );
                                                                                        } else {
                                                                                            showAlert(
                                                                                                {
                                                                                                    title:
                                                                                                        "Insufficient Privileges",
                                                                                                    text:
                                                                                                        "The role of Admin or Controller is required to configure accounting rules.",
                                                                                                    icon:
                                                                                                        "error",
                                                                                                    buttons: true
                                                                                                }
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                );
                                                                            }
                                                                        }}
                                                                    >
                                                                        {isSideNavOpen && (
                                                                            <ListItemIcon
                                                                                classes={{
                                                                                    root:
                                                                                        classes.listItemIconOverrides
                                                                                }}
                                                                            >
                                                                                {child.icon && (
                                                                                    <span
                                                                                        className={
                                                                                            classes.listIcon
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            child.icon
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                            </ListItemIcon>
                                                                        )}
                                                                        <ListItemText
                                                                            primary={
                                                                                <Typography
                                                                                    style={{
                                                                                        fontWeight: 400,
                                                                                        paddingLeft: 8
                                                                                    }}
                                                                                    className={
                                                                                        !isSideNavOpen
                                                                                            ? classes.dockedListItemText
                                                                                            : classes.listItemText
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        child.title
                                                                                    }
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                        {disableActionsForQuickView &&
                                                                            [
                                                                                "configure revrec",
                                                                                "accounting rules"
                                                                            ].includes(
                                                                                child.title.toLowerCase()
                                                                            ) && (
                                                                                <span>
                                                                                    <LockIcon
                                                                                        className={
                                                                                            classes.lockIcon
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                            )}
                                                                    </ListItem>
                                                                );
                                                            }
                                                        )}
                                                    </List>
                                                </Collapse>
                                            </div>
                                        )
                                    );
                            })}
                        </List>
                        {isSideNavOpen && disableActionsForQuickView && (
                            <Alert
                                icon={false}
                                severity="info"
                                className={classes.alert}
                            >
                                <h4 className={classes.alertHeading}>
                                    Unlock More RevRec Features
                                </h4>
                                <small>
                                    Tailor revenue recognition to your unique
                                    needs with <b>custom rules</b>, and access{" "}
                                    <b>advanced insights</b>.
                                </small>
                                <div className={classes.alertButton}>
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            this.callExpert();
                                        }}
                                        variant="contained"
                                    >
                                        <small>{"Talk to an Expert"}</small>
                                    </Button>
                                </div>
                            </Alert>
                        )}
                        {isSideNavOpen && (
                            <JobProgress
                                currentUser={currentUser}
                                currentOrganization={currentOrganization}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

MenuSidenavCompact.propTypes = {
    classes: PropTypes.shape({}).isRequired
};

function mapStateToProps(state) {
    const isSideNavOpen = sideNavOpen(state);
    if (isSideNavOpen === null) return null;
    const orgConfigs = getOrgConfigurations(state);

    const demoSiteConfig = orgConfigs && orgConfigs.find(
        (e) => e.id == "ac/demoSite"
    );
    const isDemoSite = demoSiteConfig?.value || false

    const toReturn = {
        orgConfig: getOrgConfigurations(state),
        currentUser: currentUser(state),
        currentOrganization: currentOrganization(state),
        clientTables: getClientTablesConfig(state),
        isSideNavOpen: sideNavOpen(state),
        productSpace: state.data.productSpace || "revrec",
        triggerAcTour: triggerAcTour(state),
        isDemoSite
        };
    return toReturn;
}

export default compose(
    withRouter,
    withStyles(styles),
    connect(mapStateToProps, {})
)(MenuSidenavCompact);
