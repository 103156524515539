import RecognitionRule from "./../reference/RecognitionRule";
import {
    addBillingPeriodsToPlan,
    buildDefaultRevenuePlan,
    buildPSPlan
} from "./../RevenueArrangementHelper";
import { ConfigProvider } from "./../provider/ConfigProvider";
import { Utils, Period } from "revlock-webutils";
import { RevenueArrangementItem } from "./../models/RevenueArrangement";

class CreditNoteRevenueAllocationHelper {
    /**
     * Process termination credit notes
     * @param salesOrder
     * @param revenueArrangement
     * @param creditNoteRevenueArrangementItem
     * @param nonCreditNoteRevenueArrangementItem
     * @param clientReferenceData
     * @param orgConfigByKey
     * @param options
     * @param errors
     */
    static processTerminationCreditNotes(
        salesOrder: any,
        revenueArrangement: any,
        creditNoteRevenueArrangementItem: Array<RevenueArrangementItem>,
        nonCreditNoteRevenueArrangementItem: Array<RevenueArrangementItem>,
        clientReferenceData: any,
        orgConfigByKey: any,
        options: any,
        errors: any
    ) {
        const { orgConfig, customCode } = clientReferenceData;

        const configProvider = clientReferenceData.configProvider as ConfigProvider;

        const calendarConfig = configProvider.calendarConfig();
        const unearnedByValue = configProvider.unearnedByValueConfig();

        let totalRevenueBacklog = 0;

        // 1. find revenue backlog by product and item
        let revenueBacklogByProduct: Map<
            string,
            Map<string, number>
        > = new Map();
        nonCreditNoteRevenueArrangementItem.forEach((item: any) => {
            const {
                id,
                parentSalesOrderItemId,
                productId
            } = item.salesOrderItem;
            const salesOrderItemId = parentSalesOrderItemId ?? id;

            let byItem = revenueBacklogByProduct.get(productId) || new Map();
            let revenueBacklog = byItem.get(salesOrderItemId) || 0;
            revenueBacklog += getRevenueBacklog(item, configProvider);

            byItem.set(salesOrderItemId, revenueBacklog);
            revenueBacklogByProduct.set(productId, byItem);

            totalRevenueBacklog += revenueBacklog;
        });

        // 2. reverse revenue backlog for all items
        revenueBacklogByProduct.forEach((byItem, productId) => {
            byItem.forEach((revenueBacklog, salesOrderItemId) => {
                const creditNoteItems = creditNoteRevenueArrangementItem.filter(
                    (item) => match(item, productId, salesOrderItemId)
                );
                if (creditNoteItems && creditNoteItems.length) {
                    const revenueBacklogPerItem =
                        -1 * (revenueBacklog / creditNoteItems.length);
                    creditNoteItems.forEach((item: RevenueArrangementItem) => {
                        item.productRevenue = revenueBacklogPerItem;
                    });
                }
            });
        });

        let totalCreditNoteAmount = 0;
        creditNoteRevenueArrangementItem.forEach((item) => {
            totalCreditNoteAmount += item.extendedSalePrice || 0;
        });

        // 3. calculate if there is any under or over refund
        let creditNoteDiff = totalRevenueBacklog + totalCreditNoteAmount;

        if (Math.abs(creditNoteDiff) > 0) {
            let creditNoteItems = creditNoteRevenueArrangementItem
            let nonPPCnItems = creditNoteRevenueArrangementItem.filter(
                (item: RevenueArrangementItem) =>
                    item.recognitionRuleId !=
                    RecognitionRule.PROPORTIONAL_PERFORMANCE
            );
            // 4. allocate the creditNoteDiff to all non-pp items
            if (nonPPCnItems && nonPPCnItems.length) {
                creditNoteItems = nonPPCnItems;
            }
            applyCreditNoteDiff(creditNoteItems, creditNoteDiff);
        }

        // 5. populate plan for the items based on the calculated product revenue
        for (const item of creditNoteRevenueArrangementItem) {
            const productRevenue = item.productRevenue || 0;
            item.relativeSalePricePercent =
                productRevenue / item.totalBooking || 0;

            if (
                options.createRevenuePlan &&
                ((revenueArrangement.state == "New" &&
                    item.recognitionRule &&
                    !item.hasManualUpdates) ||
                    !item.plan ||
                    item.plan.length == 0)
            ) {
                try {
                    buildDefaultRevenuePlan(
                        item,
                        salesOrder,
                        orgConfig,
                        customCode,
                        errors
                    );
                } catch (e) {
                    throw e;
                }
            }

            if (
                item.plan &&
                (item.recognitionRule.id ===
                    RecognitionRule.PROPORTIONAL_PERFORMANCE ||
                    item.recognitionRule.id ===
                        RecognitionRule.PERCENT_OF_COMPLETE)
            ) {
                buildPSPlan(
                    item,
                    salesOrder,
                    revenueArrangement,
                    errors,
                    calendarConfig,
                    orgConfig,
                    orgConfigByKey
                );
            }

            if (item.plan && unearnedByValue !== "customer") {
                addBillingPeriodsToPlan(item.plan, salesOrder, calendarConfig);
            }
        }
    }
}

function match(
    item: RevenueArrangementItem,
    productId: string,
    salesOrderItemId: string
) {
    const {
        id,
        referenceLineItemId,
        productId: soiProductId
    } = item.salesOrderItem;

    return (
        productId == soiProductId &&
        (salesOrderItemId == referenceLineItemId || salesOrderItemId == id)
    );
}

function applyCreditNoteDiff(
    creditNoteItems: RevenueArrangementItem[],
    creditNoteDiff: number
) {
    let totalESP = 0;
    creditNoteItems.forEach((item) => {
        totalESP += item.extendedEstimatedSalePrice ?? 0;
    });
    creditNoteItems.forEach((item) => {
        const itemESP = item.extendedEstimatedSalePrice ?? 0;
        let proportion = itemESP / totalESP;
        if (isNaN(proportion) || !isFinite(proportion)) {
            proportion = 0;
        }
        item.productRevenue = item.productRevenue ?? 0;
        item.productRevenue += creditNoteDiff * proportion;
    });
}

function getRevenueBacklog(
    item: RevenueArrangementItem,
    configProvider: ConfigProvider
): number {
    let backlog = 0;
    let deliveryEndDate = item.deliveryEndDate;
    if (!configProvider.endDateInclusiveConfig()) {
        deliveryEndDate = Utils.addDays(deliveryEndDate, -1);
    }
    const deliveryEndPeriod = Period.toPeriod(deliveryEndDate);
    if (item && item.plan && item.plan.length) {
        item.plan.forEach((p: any) => {
            const planPeriod = p.actgPeriod.period;
            if (Period.isSameOrAfter(planPeriod, deliveryEndPeriod)) {
                backlog += p.planAmount;
            }
        });
    }
    return backlog;
}

module.exports = CreditNoteRevenueAllocationHelper;
