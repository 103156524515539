import React from "react";
import {A} from "../../components/ImgIcon";
import logoImage from 'assets/images/chargebee-icon-orange.png'
function Title(props) {
    const {msg, src = logoImage} = props;

    return (<div style={{paddingTop: 10, display: 'flex', justifyContent: 'center'}}>{msg}</div>)

}

export const allTours = {
    basicTour: {
        steps: [
            {
                content: 'This is our sidebar, menu. Quickly get to sales, customers, revenue plans or reporting',
                placement: 'right',
                spotlightPadding: 0,
                spotlightClicks: true,
                styles: {
                    options: {
                        zIndex: 10000,
                    },
                },
                target: '#menu',
                title: <Title msg={'The Menu'}></Title>,
                disableBeacon: true,
                disableOverlayClose: true,
                hideCloseButton: false,
            },
            {
                content: (<div>
                    Your current accounting period drives which sales are recorded in which closing period. Read <A
                    href="https://www.chargebee.com/docs/revrec-results.html#managing-revenue-subledger_managing-account-closing">more</A>
                </div>),
                placement: 'right',
                spotlightClicks: true,
                spotlightPadding: 12,
                styles: {
                    options: {
                        zIndex: 10000,
                    },
                },
                target: '#actg_period',
                title: <Title msg={'Accounting Period'}></Title>
            },
            {
                content: (<div>
                    You get multiple environments with Chargebee RevRec. Your test environment is your play area to try things
                    out. Read <A href="https://www.chargebee.com/docs/revrec/understanding-environments.html">more</A>
                </div>),
                placement: 'right',
                spotlightClicks: true,
                spotlightPadding: 0,
                styles: {
                    options: {
                        zIndex: 10000,
                    },
                },
                target: '#environments',
                title: <Title msg={'Your Environments'}></Title>
            },
            {
                content: (
                    <div>
                        Get help whenever you need it.
                        <br/>
                        Documentation, videos or live help.
                    </div>
                ),
                disableBeacon: true,
                disableOverlayClose: true,
                hideCloseButton: false,
                placement: 'bottom',
                spotlightClicks: true,
                spotlightPadding: 8,
                styles: {
                    options: {
                        zIndex: 10000,
                    },
                },
                target: '#help',
                title: <Title msg={'Get help...'}></Title>,
            },
            {
                content: (<div>
                    Keep your account secure. Manage your credentials (password, MFA, phone etc).
                </div>),
                placement: 'top',
                spotlightClicks: true,
                spotlightPadding: 8,
                styles: {
                    options: {
                        zIndex: 10000,
                    },
                },
                target: '#my-account',
                title: <Title msg={'Your Account'}></Title>
            }
        ]
    },
    AcOnboardingTour:{
        steps: [
            {
                content: 'Have all financial results for A/R, Cash, Sales Tax, Revenue and Expense and track key metrics in one place.',
                placement: 'left',
                spotlightPadding: 0,
                spotlightClicks: true,
                styles: {
                    options: {
                        zIndex: 10000,
                    },
                },
                target: '#home',
                title: <Title msg={'Home'}></Title>,
                disableBeacon: true,
                disableOverlayClose: true,
                data:{
                    next:`customers`
                },
                hideCloseButton: false,
            },
            {
                content: 'View list of all the customers.',
                placement: 'top',
                spotlightPadding: 0,
                spotlightClicks: true,
                allowClicksThruHole:true,
                styles: {
                    options: {
                        zIndex: 10000,
                    },
                },
                target: '#ac-customers',
                title: <Title msg={'Customer'}></Title>,
                disableBeacon: true,
                disableOverlayClose: true,
                data: {
                    previous:"/",
                    next: 'customers/9013?name=Wonka%20Industries',
                  },
                hideCloseButton: false,
            },
            {
                content: 'View details at customer level from billing and payments to revenue and journal entries.',
                placement: 'top',
                spotlightPadding: 0,
                spotlightClicks: true,
                allowClicksThruHole:true,
                styles: {
                    options: {
                        zIndex: 10000,
                    },
                },
                target: '#ac-customer-overview',
                title: <Title msg={'Customer Details'}></Title>,
                disableBeacon: true,
                disableOverlayClose: true,
                data: {
                    previous: 'customers',
                    next: 'accountsReceivable'
                  },
                hideCloseButton: false,
            },
            {
                content: 'Quickly understand your A/R activities, balances and aging, and monthly trends.',
                placement: 'bottom',
                spotlightPadding: 0,
                spotlightClicks: true,
                allowClicksThruHole:true,
                styles: {
                    options: {
                        zIndex: 10000,
                    },
                },
                target: '#ac-accountsReceivable',
                title: <Title msg={'Accounts Receivables'}></Title>,
                disableBeacon: true,
                disableOverlayClose: true,
                data: {
                    previous: 'customers/Base-Currency?name=GrowthEdge%20Analytics',
                    next: 'reports'
                  },
                hideCloseButton: false,
            },
            {
                content: 'Gain insights with pre-built reports from trial balance, AR rollforward and aging, to data validation, control and audit reports.',
                placement: 'bottom',
                spotlightPadding: 0,
                spotlightClicks: true,
                allowClicksThruHole:true,
                styles: {
                    options: {
                        zIndex: 10000,
                    },
                },
                target: '#ac-reports',
                title: <Title msg={'Report Explorer'}></Title>,
                disableBeacon: true,
                disableOverlayClose: true,
                data: {
                    previous: 'accountsReceivable',
                    next: 'jams'
                  },
                hideCloseButton: false,
            },
            {
                content: 'Utilize custom fields to flexibly define and map your general ledger account rules for business events.',
                placement: 'bottom',
                spotlightPadding: 0,
                spotlightClicks: true,
                allowClicksThruHole:true,
                styles: {
                    options: {
                        zIndex: 10000,
                    },
                },
                target: '#ac-mappings',
                title: <Title msg={'GL Mapping'}></Title>,
                disableBeacon: true,
                disableOverlayClose: true,
                data: {
                    previous: 'accountsReceivable',
                    next:'closing-reports'
                  },
                hideCloseButton: false,
            },
            {
                content: 'Use readily available validation reports to close the book confidently and efficiently.',
                placement: 'bottom',
                spotlightPadding: 0,
                spotlightClicks: true,
                allowClicksThruHole:true,
                styles: {
                    options: {
                        zIndex: 10000,
                    },
                },
                target: '#ac-monthly-close',
                title: <Title msg={'Monthly Close'}></Title>,
                disableBeacon: true,
                disableOverlayClose: true,
                data: {
                    previous: 'jams',
                    next:'journal-posting'
                  },
                hideCloseButton: false,
            },
            {
                content: 'Post summary or detailed journal entries to your GL system, on a daily, weekly or monthly basis.',
                placement: 'bottom',
                spotlightPadding: 0,
                spotlightClicks: true,
                allowClicksThruHole:true,
                styles: {
                    options: {
                        zIndex: 10000,
                    },
                },
                target: '#ac-post-je',
                title: <Title msg={'Post Journal Entries'}></Title>,
                disableBeacon: true,
                disableOverlayClose: true,
                data: {
                    previous: 'closing-reports',
                  },
                hideCloseButton: false,
            }
        ]
    }
}
