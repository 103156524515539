import ModificationType from "./reference/ModificationType";
import TransactionType from "./reference/TransactionType";
import ComponentType from "./reference/ComponentType";
import {
    prepareScheduleBySalesOrderItemId,
    adjustForBilling
} from "./BillingScheduleHelper";
import { Utils, Comparator, Period } from "revlock-webutils";
import shortid from "shortid";
import _ from "lodash";

const endOfTransactions = {
    end: true
};

const CONFIG = {
    revenue: {
        componentType: ComponentType.UNEARNED_REVENUE,
        originalTransactionType:
            TransactionType.PERFORMANCE_OBLIGATION_ORIGIN.name,
        ammortizationTransactionType: TransactionType.REVENUE.name,
        getPlan: (item) => item.plan || [],
        getTotalBooking: (item) =>
            item.terminationDate
                ? item.terminatedProductRevenue
                : item.productRevenue,
        getRecognitionRule: (item) => item.recognitionRule,
        getEndDate: (item) => item.deliveryEndDate,
        getJournalTransactions: (O) => O.reportingTransactions,
        addJournalEntries: (O, journalEntries) =>
            (O.journalEntries = journalEntries),
        createEntries: (
            reportingTransaction,
            salesOrder,
            salesOrderItemId,
            config,
            defaultAccounts,
            jeMappingColumns,
            reasonCodesValue,
            bookJEExchangeRateSeparately = false
        ) => {
            let transactions = [];

            let creditAccount = defaultAccounts["Revenue"] || {
                accountType: "Revenue"
            };

            if (reportingTransaction["Foreign Currency Change"] != 0) {
                transactions.push({
                    "Component Type": ComponentType.UNEARNED_REVENUE,
                    "Transaction Type": bookJEExchangeRateSeparately
                        ? TransactionType.FX_GAIN_LOSS.name
                        : TransactionType.REVENUE.name,
                    Amount: reportingTransaction["Foreign Currency Change"],
                    "Link Id": `RevRec_${reportingTransaction["Actg Period"]}`,
                    "SOI Version Item Id": salesOrderItemId
                });

                if (bookJEExchangeRateSeparately) {
                    creditAccount = defaultAccounts["Realized FX Gain Loss"] ||
                        defaultAccounts["Revenue"] || {
                            accountType: "Realized FX Gain Loss"
                        };
                }
            }

            if (reportingTransaction["Billing Adjustment"] != 0) {
                transactions.push({
                    "Component Type": ComponentType.UNEARNED_REVENUE,
                    "Transaction Type": TransactionType.DEF_REV_ADJUSTMENT.name,
                    Amount: -1 * reportingTransaction["Billing Adjustment"],
                    "Link Id": `RevDef_${reportingTransaction["Actg Period"]}`,
                    "SOI Version Item Id": salesOrderItemId
                });
            }

            if (reportingTransaction["Revenue"] != 0) {
                transactions.push({
                    "Component Type": ComponentType.UNEARNED_REVENUE,
                    "Transaction Type": TransactionType.REVENUE.name,
                    Amount: reportingTransaction["Revenue"],
                    "Link Id": `RevRec_${reportingTransaction["Actg Period"]}`,
                    "SOI Version Item Id": salesOrderItemId,
                    "Is PPA": reportingTransaction["Is PPA"]
                });
            }

            if (reportingTransaction["New Billing"] != 0) {
                transactions.push({
                    "Component Type": ComponentType.UNEARNED_REVENUE,
                    "Transaction Type":
                        TransactionType.PERFORMANCE_OBLIGATION_ORIGIN.name,
                    Amount: -1 * reportingTransaction["New Billing"],
                    "Link Id": `RevDef_${reportingTransaction["Actg Period"]}`,
                    "SOI Version Item Id": salesOrderItemId
                });
            }

            // Build base journal mapping value.
            const baseJournalMappingValues = [];
            const jeMappingColumnsNames = [];

            for (const jeMappingColumn of jeMappingColumns) {
                baseJournalMappingValues.push(
                    reportingTransaction[jeMappingColumn] || "JEMINVOICE"
                );

                jeMappingColumnsNames.push(jeMappingColumn);
            }

            const baseJournalMappingValue = baseJournalMappingValues.join(
                ":=:"
            );

            let hasReasonCodeAttribute;

            if (
                jeMappingColumnsNames.length &&
                reasonCodesValue &&
                reasonCodesValue.length
            ) {
                hasReasonCodeAttribute = Object.keys(reportingTransaction).find(
                    (k) => {
                        if (
                            jeMappingColumnsNames.includes(k) &&
                            reasonCodesValue.includes(reportingTransaction[k])
                        ) {
                            return k;
                        }
                    }
                );

                if (hasReasonCodeAttribute) {
                    creditAccount = defaultAccounts["Bad Debt Expense"] || {
                        accountType: "Bad Debt Expense"
                    };
                }
            }

            let journalEntries = [];
            transactions.forEach((transaction) => {
                let amount = transaction["Amount"];

                delete transaction["Amount"];

                if (amount == 0) {
                    return;
                }

                let inverse = amount < 0;
                amount = Math.abs(amount);

                let debitAccount = defaultAccounts["Deferred Revenue"] || {
                    accountType: "Deferred Revenue"
                };

                // REV_DEFERRAL Transaction is when there is New_Billing <> 0
                if (
                    transaction["Transaction Type"] ===
                        config.originalTransactionType ||
                    transaction["Transaction Type"] ===
                        TransactionType.DEF_REV_ADJUSTMENT.name
                ) {
                    // Taking out amount from Contra revenue instead of Revenue Account here.
                    creditAccount = Utils.cloneDeep(creditAccount);
                    creditAccount.accountType = "Contra Revenue";
                } else if (
                    transaction["Is PPA"] == 1 &&
                    !hasReasonCodeAttribute
                ) {
                    // Revenue PPA transaction but shouldn't be bad debt expense
                    creditAccount = Utils.cloneDeep(creditAccount);
                    creditAccount.accountType = "Revenue PPA";
                }

                if (inverse) {
                    [debitAccount, creditAccount] = [
                        creditAccount,
                        debitAccount
                    ];
                }

                reportingTransaction["Transaction Date"] =
                    reportingTransaction["Transaction Date"];

                let baseEntry = Object.assign(
                    {},
                    _.pick(reportingTransaction, [
                        "OrgId",
                        "Actg Period",
                        "Transaction Date",
                        "Sales Order Item Id",
                        "Sales Order Item Root Id",
                        "Customer Id",
                        "Sales Order Id",
                        "Linked Sales Order Id",
                        "Product Id",
                        "Job Id",
                        "IsActive",
                        "Active Link Id",
                        "Currency",
                        "Home Currency",
                        "IsAdjustment",
                        "Effective Period",
                        "Dunning Status",
                        "Invoice Status",
                        "Delayed Revenue",
                        "Is Revenue Paused",
                        "Pause Revenue Date"
                    ]),
                    transaction
                );

                baseEntry["Sales Order Item Id"] =
                    reportingTransaction["Sales Order Item Root Id"];
                baseEntry["Channel"] = reportingTransaction["Channel"];
                baseEntry["App Name"] = reportingTransaction["App Name"];

                // These are for expense only but if we don't set them as follows
                // then they end up in JE table as empty strings instead of NULL
                baseEntry["Reference Number"] = "\\N";
                baseEntry["Expense Type"] = "\\N";
                baseEntry["Expense Code"] = "\\N";
                baseEntry["Class"] = "\\N";
                baseEntry["Department"] = "\\N";
                baseEntry["Location"] = "\\N";
                baseEntry["Sales Person"] = "\\N";
                baseEntry["Transaction_Id"] = "\\N";

                // Ensures that the AdjustmentType is NULL and not empty string
                // if its not specified.
                const isAdjustment = reportingTransaction["IsAdjustment"];
                baseEntry["AdjustmentType"] =
                    isAdjustment == 1
                        ? reportingTransaction["AdjustmentType"]
                        : "\\N";

                let debitEntry = Object.assign(
                    {
                        "Account Id": debitAccount && debitAccount.id,
                        "Account Type":
                            debitAccount && debitAccount.accountType,
                        "Debit Amount": amount,
                        "Credit Amount": 0,
                        "Net Amount": amount
                    },
                    baseEntry,
                    {
                        "Journal Mapping Key":
                            baseJournalMappingValue.length > 0
                                ? baseJournalMappingValue +
                                  ":=:" +
                                  debitAccount.accountType
                                : debitAccount.accountType
                    }
                );

                let creditEntry = Object.assign(
                    {
                        "Account Id": creditAccount && creditAccount.id,
                        "Account Type":
                            creditAccount && creditAccount.accountType,
                        "Debit Amount": 0,
                        "Credit Amount": -1 * amount,
                        "Net Amount": -1 * amount
                    },
                    baseEntry,
                    {
                        "Journal Mapping Key":
                            baseJournalMappingValue.length > 0
                                ? baseJournalMappingValue +
                                  ":=:" +
                                  creditAccount.accountType
                                : creditAccount.accountType
                    }
                );

                journalEntries.push(debitEntry, creditEntry);
            });

            return journalEntries;
        }
    }
};

export function generateTransactions(
    salesOrder,
    orgConfigByKey = {},
    calendarConfig
) {
    let config = CONFIG["revenue"];
    const revenueWithPPA =
        (orgConfigByKey["properties/enable_prior_period_adj"] &&
            orgConfigByKey["properties/enable_prior_period_adj"].value) ||
        false;
    const useWhDataForReversals =
        (orgConfigByKey["properties/use_warehouse_data_for_reversal"] &&
            orgConfigByKey["properties/use_warehouse_data_for_reversal"]
                .value) ||
        false;

    let { getEndDate } = config;

    if (!salesOrder.currentRevenueArrangement) return;

    // Order arrangements by effective date.
    let comparator = (ra1, ra2) =>
        ra2.effectiveDate < ra1.effectiveDate ? 1 : -1;
    salesOrder.revenueArrangement.sort(comparator);

    // Collect transactions in following array.
    let transactions = [];
    let needOriginalTransactions = true;
    const lastestVersionItem = {};
    salesOrder.currentRevenueArrangement.revenueArrangementItem.forEach(
        (rai) => {
            let _itemId = rai.salesOrderItem.rootId || rai.salesOrderItem.id;
            lastestVersionItem[_itemId] = rai.salesOrderItem.id;
        }
    );

    const notNewArrangements = salesOrder.revenueArrangement.filter(
        (ra) => ra.state != "New"
    );
    notNewArrangements.forEach((revenueArrangement) => {
        revenueArrangement.revenueArrangementItem.forEach((rai) => {
            let _itemId = rai.salesOrderItem.rootId || rai.salesOrderItem.id;
            if (!lastestVersionItem[_itemId])
                lastestVersionItem[_itemId] = rai.salesOrderItem.id;
        });
    });

    const getMaxTransactionDate = (revenueArrangement, rai) => {
        let maxTransactionDate = rai.transactionDate;
        const _itemId = rai.salesOrderItem.rootId || rai.salesOrderItem.id;

        revenueArrangement.revenueArrangementItem.forEach((item) => {
            const itemId = item.salesOrderItem.rootId || item.salesOrderItem.id;
            if (
                _itemId == itemId &&
                item.transactionDate > maxTransactionDate
            ) {
                maxTransactionDate = item.transactionDate;
            }
        });

        return maxTransactionDate;
    };

    const getMaxTransactionDateBySalesOrderItemId = (
        revenueArrangement,
        salesOrderItemRootId
    ) => {
        let maxTransactionDate;
        const _itemId = salesOrderItemRootId;

        for (const item of revenueArrangement.revenueArrangementItem) {
            const itemId = item.salesOrderItem.rootId || item.salesOrderItem.id;
            if (_itemId == itemId) {
                if (
                    !maxTransactionDate ||
                    (maxTransactionDate &&
                        item.transactionDate > maxTransactionDate)
                ) {
                    maxTransactionDate = item.transactionDate;
                }

                break;
            }
        }

        return maxTransactionDate;
    };

    const maxEndDateByArrangementIds = {};

    salesOrder.revenueArrangement.forEach((revenueArrangement) => {
        let maxEndDate = revenueArrangement.endDate;
        if (!maxEndDate) {
            // we don't have endDate, let's find the max end date
            maxEndDate =
                revenueArrangement.revenueArrangementItem[0].deliveryEndDate;
            revenueArrangement.revenueArrangementItem.forEach((item) => {
                let _endDate = getEndDate(item);
                if (_endDate > maxEndDate) {
                    maxEndDate = _endDate;
                }

                maxEndDateByArrangementIds[revenueArrangement.id] = maxEndDate;
            });
        } else {
            // we have an endDate, and this is what we need.
            maxEndDateByArrangementIds[revenueArrangement.id] =
                revenueArrangement.endDate;
        }
    });

    // For each arrangement,
    salesOrder.revenueArrangement.forEach((revenueArrangement) => {
        if (revenueArrangement.state == "New") {
            return;
        }

        let { endDate, modificationType } = revenueArrangement;
        const effectiveDatePeriod = Period.toActgPeriod(
            revenueArrangement.effectiveDate,
            calendarConfig
        );
        let arrangementFirstActgPeriod = effectiveDatePeriod;

        // If end date is null i.e. arrangement is active
        if (!endDate) {
            // get maxEndDate from maxEndDateByArrangementIds
            endDate = maxEndDateByArrangementIds[revenueArrangement.id];
        }

        const endPeriod = Period.toActgPeriod(endDate, calendarConfig);
        endDate = endPeriod.endDate;

        // Current effective date is arrangement's effective date, this might change if arrangement has retrospective mod.
        const currentActgPeriod = Period.toActgPeriod(
            Period.currentDate(),
            calendarConfig
        );
        const currentPostingPeriod = currentActgPeriod.period;
        const currentPostingPeriodDate = currentActgPeriod.endDate;

        // If retrospective MOD
        if (
            modificationType &&
            (modificationType === ModificationType.RETROSPECTIVE ||
                modificationType === ModificationType.PROSPECTIVE)
        ) {
            if (!useWhDataForReversals) {
                // Create reversal transaction for all existing enteries
                let reversals = transactions.reduce((result, transaction) => {
                    // Only reverse where transaction type is REVENUE.
                    if (!transaction.skipReversal) {
                        const _actgPeriod = arrangementFirstActgPeriod;
                        let actgPeriod = _actgPeriod;

                        const maxTransactionDate = getMaxTransactionDateBySalesOrderItemId(
                            revenueArrangement,
                            transaction.salesOrderItemRootId
                        );
                        if (maxTransactionDate) {
                            const maxTransactionDatePeriod = Period.toActgPeriod(
                                maxTransactionDate,
                                calendarConfig
                            );
                            if (
                                maxTransactionDatePeriod.period >
                                _actgPeriod.period
                            ) {
                                actgPeriod = maxTransactionDatePeriod;
                            }
                        }
                        // If transaction date is null then maxTransaction period's endDate will be transaction date
                        const transactionDate =
                            (revenueWithPPA && transaction.effectiveDate) ||
                            actgPeriod.endDate;
                        const effectivePeriod = Period.toActgPeriod(
                            transactionDate,
                            calendarConfig
                        );
                        const is_PPA = Period.isBefore(
                            effectivePeriod.period,
                            actgPeriod.period
                        );
                        const isFutureTransaction = Period.isSameOrAfter(
                            actgPeriod.startDate,
                            currentPostingPeriodDate
                        );

                        const reversal = {
                            id: transaction.id + "_reversal",
                            actgPeriod,
                            revenueArrangementItemId:
                                transaction.revenueArrangementItemId,
                            salesOrderItemId:
                                lastestVersionItem[
                                    transaction.salesOrderItemRootId
                                ],
                            salesOrderItemRootId:
                                transaction.salesOrderItemRootId,
                            effectiveDate: transactionDate,
                            postingDate: actgPeriod.postingDate,
                            period: transaction.period,
                            postingPeriod: actgPeriod.period,
                            postingYear: actgPeriod.strYear,
                            amount: -1 * transaction.amount,
                            productRevenue: transaction.productRevenue,
                            extendedSalePrice:
                                -1 * transaction.extendedSalePrice,
                            transactionType: transaction.transactionType,
                            componentType: transaction.componentType,
                            isFutureTransaction: isFutureTransaction,
                            totalBooking: transaction.totalBooking,
                            processType: "REVERSAL",
                            productId: transaction.productId,
                            customerId: transaction.customerId,
                            salesOrderId: salesOrder.id,
                            isActive: transaction.isActive,
                            isModification: true,
                            channel: transaction.channel,
                            app_name: transaction.app_name,
                            is_PPA: is_PPA,
                            effectivePeriod: effectivePeriod.period
                        };

                        // No need to reverse again in case there are more mods.
                        transaction.skipReversal = true;
                        reversal.skipReversal = true;

                        /*
                                reversal.cummulativePercentRecognized += (reversal.amount / reversal.totalBooking)
                                */
                        result.push(reversal);
                    }
                    return result;
                }, []);

                // Skip Reversals Here Based on Config
                transactions.push(...reversals);
            }

            needOriginalTransactions = true;
        }

        if (needOriginalTransactions) {
            const _originActgPeriod = Period.toActgPeriod(
                revenueArrangement.effectiveDate,
                calendarConfig
            );

            revenueArrangement.revenueArrangementItem.forEach((item) => {
                let originActgPeriod = _originActgPeriod;
                let transactionDate = item.transactionDate;
                if (transactionDate) {
                    const itemTransactionDatePeriodObj = Period.toActgPeriod(
                        item.transactionDate,
                        calendarConfig
                    );
                    if (
                        itemTransactionDatePeriodObj.period >=
                        _originActgPeriod.period
                    ) {
                        originActgPeriod = itemTransactionDatePeriodObj;
                    }
                } else {
                    transactionDate = Period.toActgPeriod(
                        salesOrder.orderDate,
                        calendarConfig
                    ).endDate;
                }

                if (revenueWithPPA == false) {
                    transactionDate = originActgPeriod.endDate;
                }
                let totalBooking = config.getTotalBooking(item);
                let { salesOrderItem } = item;
                let isMod = revenueArrangement.state == "Closed";
                const effectivePeriod = Period.toActgPeriod(
                    transactionDate,
                    calendarConfig
                );
                const is_PPA = Period.isBefore(
                    effectivePeriod.period,
                    originActgPeriod.period
                );
                // Unearned revenue transaction.
                const isFutureTransaction = Period.isSameOrAfter(
                    originActgPeriod.endDate,
                    currentPostingPeriodDate
                );

                let transaction = {
                    id: shortid.generate(),
                    actgPeriod: originActgPeriod,
                    revenueArrangementItemId: item.id,
                    salesOrderItemId:
                        lastestVersionItem[
                            item.salesOrderItem.rootId || item.salesOrderItemId
                        ],
                    salesOrderItemRootId:
                        item.salesOrderItem.rootId || item.salesOrderItemId,
                    effectiveDate: transactionDate,
                    postingDate: originActgPeriod.postingDate,
                    period: originActgPeriod.period,
                    postingPeriod: originActgPeriod.period,
                    postingYear: originActgPeriod.strYear,
                    amount: -1 * totalBooking,
                    productRevenue: item.terminationDate
                        ? item.terminatedProductRevenue
                        : item.productRevenue,
                    extendedSalePrice: item.extendedSalePrice,
                    transactionType: config.originalTransactionType,
                    componentType: config.componentType,
                    isFutureTransaction: isFutureTransaction,
                    totalBooking: totalBooking,
                    processType: "NORMAL",
                    productId: salesOrderItem.productId,
                    customerId: salesOrder.customerId,
                    salesOrderId: salesOrder.id,
                    isActive: item.isActive,
                    isModification: isMod,
                    channel: item.channel,
                    app_name: item.app_name,
                    is_PPA: is_PPA,
                    effectivePeriod: Period.toActgPeriod(
                        transactionDate,
                        calendarConfig
                    ).period
                };

                transactions.push(transaction);
            });
            needOriginalTransactions = false;
        }

        revenueArrangement.revenueArrangementItem.forEach((item) => {
            let plan = config.getPlan(item);

            let {
                salesOrderItem,
                transactionDate,
                servicePeriodExtendedDate
            } = item;

            // let's say a closed state revenueArrangement is type mod for now
            let isMod = revenueArrangement.state == "Closed";
            let newTransactions = plan.reduce((result, planElement) => {
                // Scan period from current effective date to end date
                // we are comparing endDate (function) which would give us un-expected behavior
                let _endDate = Utils.formatDate(endDate);

                // - This only is set when service delivery ratable method is used.
                // - Refer to ProcessionalServiceHelper.updatePSPlanRatable(...) method to see how this is populated.
                // - servicePeriodExtension only matters for the CURRENT arrangement because, for all prior arrangement,
                //   we will reverse out all closed period transactions.
                if (!isMod && servicePeriodExtendedDate) {
                    _endDate = servicePeriodExtendedDate;
                }

                // effectiveDate is always endDate of the accounting period.
                let effectiveDate = planElement.actgPeriod.effectiveDate;
                if (revenueWithPPA == true && planElement.effectivePeriod) {
                    effectiveDate = Period.toActgPeriod(
                        planElement.effectivePeriod,
                        calendarConfig
                    ).endDate;
                }
                if (
                    isMod & Period.isBefore(effectiveDate, _endDate) ||
                    (!isMod && Period.isSameOrBefore(effectiveDate, _endDate))
                ) {
                    let postingDate = planElement.actgPeriod.postingDate;
                    let postingPeriod = planElement.actgPeriod.period;

                    let period = planElement.actgPeriod.period;
                    let postingYear = Period.toYear(
                        planElement.actgPeriod.effectiveDate
                    );

                    let raEffectiveDate = revenueArrangement.effectiveDate;
                    let _arrangementFirstActgPeriod = arrangementFirstActgPeriod;

                    if (
                        transactionDate &&
                        Period.isSameOrAfter(transactionDate, raEffectiveDate)
                    ) {
                        // if we have transaction date at the item level and this date is > effective date of ra
                        // then we use transaction date as oppsed to effective date.
                        raEffectiveDate = transactionDate;

                        // Arrangement first period at item level will always be max transaction date period
                        const maxTransactionDate = getMaxTransactionDate(
                            revenueArrangement,
                            item
                        );

                        if (maxTransactionDate) {
                            const maxTransactionDatePeriod = Period.toActgPeriod(
                                maxTransactionDate,
                                calendarConfig
                            );
                            if (
                                maxTransactionDatePeriod.period >=
                                _arrangementFirstActgPeriod.period
                            ) {
                                _arrangementFirstActgPeriod = maxTransactionDatePeriod;
                            }
                        }
                    }
                    let currActgPeriod = planElement.actgPeriod;
                    if (Period.isBefore(effectiveDate, raEffectiveDate)) {
                        postingDate = _arrangementFirstActgPeriod.postingDate;
                        postingPeriod = _arrangementFirstActgPeriod.period;
                        postingYear = _arrangementFirstActgPeriod.strYear;
                        currActgPeriod = _arrangementFirstActgPeriod;
                    }
                    if (revenueWithPPA == false) {
                        currActgPeriod = planElement.actgPeriod;
                    }
                    const effectivePeriod = Period.toActgPeriod(
                        effectiveDate,
                        calendarConfig
                    ).period;
                    // can't use raeffective date to determine is ppa as
                    // in psd we don't have any new arrangement when prior period's service delivery logs are modified
                    const is_PPA = Period.isBefore(
                        effectivePeriod,
                        currActgPeriod.period
                    );

                    if (isNaN(planElement.planAmount))
                        throw new Error("Bad plan found");

                    // Create plan transaction.
                    let t = {
                        id: shortid.generate(),
                        actgPeriod: currActgPeriod,
                        revenueArrangementItemId: item.id,
                        salesOrderItemId:
                            lastestVersionItem[
                                item.salesOrderItem.rootId ||
                                    item.salesOrderItemId
                            ],
                        salesOrderItemRootId:
                            item.salesOrderItem.rootId || item.salesOrderItemId,
                        effectiveDate: effectiveDate,
                        postingDate: postingDate,
                        period: period,
                        postingPeriod: postingPeriod,
                        postingYear: postingYear,
                        amount: planElement.planAmount,
                        productRevenue: item.terminationDate
                            ? item.terminatedProductRevenue
                            : item.productRevenue,
                        extendedSalePrice: item.extendedSalePrice,
                        transactionType: config.ammortizationTransactionType,
                        componentType: config.componentType,
                        isFutureTransaction: Period.isSameOrAfter(
                            postingPeriod,
                            currentPostingPeriod
                        ),
                        totalBooking: config.getTotalBooking(item),
                        processType: "NORMAL",
                        productId: salesOrderItem.productId,
                        customerId: salesOrder.customerId,
                        salesOrderId: salesOrder.id,
                        jobId: salesOrder.jobId,
                        isActive: item.isActive,
                        isModification: isMod,
                        channel: item.channel,
                        app_name: item.app_name,
                        is_PPA: is_PPA,
                        effectivePeriod: effectivePeriod
                    };

                    result.push(t);
                }

                return result;
            }, []);
            transactions.push(...newTransactions);
        });
    });

    salesOrder.transactions = transactions;
    const periods = Array.from(
        new Set(transactions.map((t) => t.actgPeriod.period))
    ).sort();

    salesOrder.firstPeriod = periods[0];
    salesOrder.lastPeriod = periods[periods.length - 1];
}

export function generateWaterFall(salesOrder) {
    let config = CONFIG["revenue"];

    let transactions = salesOrder.transactions;

    let waterFall = salesOrder.salesOrderItem.reduce(
        (result, salesOrderItem) => {
            let salesOrderItemId = salesOrderItem.rootId || salesOrderItem.id;
            result[salesOrderItemId] = { salesOrderItem, salesOrderItemId };
            return result;
        },
        {}
    );

    let currentYear = Utils.getYear(Utils.currentDate());
    let currentPostingPeriod = Utils.formatShortestDateWithYear(
        Utils.currentDate()
    );
    let totalPeriodWaterFall = { salesOrderItemId: 0 };
    waterFall[0] = totalPeriodWaterFall;

    // generate waterfall with revenue, revenue-ppa and total
    const addTransaction = (index, transaction, itemWaterFall) => {
        if (!itemWaterFall[index]) {
            itemWaterFall[index] = {
                total: 0,
                revenue: 0,
                revenuePPA: 0
            };
        }
        itemWaterFall[index].total += transaction.amount;

        if (transaction.is_PPA == true) {
            itemWaterFall[index].revenuePPA += transaction.amount;
        } else {
            itemWaterFall[index].revenue += transaction.amount;
        }
    };

    transactions.forEach((transaction) => {
        if (transaction.transactionType != config.ammortizationTransactionType)
            return;

        // Only adding waterfall for items which are active
        if (transaction.isActive) {
            // Monthly water fall for current year only.
            let period = transaction.postingPeriod;
            let year = transaction.postingYear;

            let itemWaterFall = waterFall[transaction.salesOrderItemRootId];

            // By monthly period
            addTransaction(period, transaction, itemWaterFall);

            // By year
            addTransaction(year, transaction, itemWaterFall);

            // Year to date
            if (
                (!transaction.isFutureTransaction && year == currentYear) ||
                (transaction.isFutureTransaction &&
                    period == currentPostingPeriod)
            ) {
                let ytdAmount = itemWaterFall["ytd"] || 0;
                ytdAmount += transaction.amount;
                itemWaterFall["ytd"] = ytdAmount;
            }

            // Full total
            addTransaction(period, transaction, totalPeriodWaterFall);
        }
    });

    salesOrder.waterFall = waterFall;
}

export function generateSalesJournalReportData(
    salesOrder,
    journalAccounts,
    transactions,
    jeMappingColumns,
    orgConfig
) {
    let config = CONFIG["revenue"];

    let journalEntries = [];

    let defaultAccounts = {};
    journalAccounts.forEach((account) => {
        if (account.isDefault) defaultAccounts[account.accountType] = account;
    });

    const reasonCodesConfig = orgConfig.find(
        (cfg) => cfg.id === "properties/expense/reasoncodes"
    );
    const reasonCodesValue = reasonCodesConfig && reasonCodesConfig.value;

    const bookJEExchangeRateSeparatelyConfig = orgConfig.find(
        (cfg) => cfg.id === "properties/journalentries/book-exchange-rate"
    );
    const bookJEExchangeRateSeparately =
        bookJEExchangeRateSeparatelyConfig &&
        bookJEExchangeRateSeparatelyConfig.value;

    transactions.forEach((transaction) => {
        if (transaction.componentType === config.componentType) {
            // Only report the unearned revenue component
            journalEntries.push(
                ...config.createEntries(
                    transaction,
                    salesOrder,
                    transaction["Sales Order Item Id"],
                    config,
                    defaultAccounts,
                    jeMappingColumns,
                    reasonCodesValue,
                    bookJEExchangeRateSeparately
                )
            );
        }
    });

    config.addJournalEntries(salesOrder, journalEntries);
}

export function getTransactionsForReporting(
    salesOrder,
    orgConfigByKey,
    calendarConfig
) {
    const enableAdjustmentToUnearnedRevenue =
        (orgConfigByKey["properties/enableAdjustmentToUnearnedRevenue"] &&
            orgConfigByKey["properties/enableAdjustmentToUnearnedRevenue"]
                .value) ||
        false;

    const billingByContract =
        (orgConfigByKey["properties/billing/by-contract"] &&
            orgConfigByKey["properties/billing/by-contract"].value) ||
        false;

    const revenueWithPPA =
        (orgConfigByKey["properties/enable_prior_period_adj"] &&
            orgConfigByKey["properties/enable_prior_period_adj"].value) ||
        false;

    const recognizeByTransactionDate =
        orgConfigByKey["properties/recognize-by-transaction-date"]?.value ||
        false;

    const { transactions } = salesOrder;

    let revenueArrangementItemById = salesOrder.revenueArrangement.reduce(
        (result, arrangement) => {
            arrangement.revenueArrangementItem.forEach(
                (item) => (result[item.id] = item)
            );
            return result;
        },
        {}
    );

    // Maintains a map of rev arrangement that was created when one arrangement was closed.
    let nextRevenueArrangementItemById = salesOrder.revenueArrangement.reduce(
        (result, arrangement, index, allArrangements) => {
            if (index < allArrangements.length - 1) {
                let nextArrangement = allArrangements[index + 1];
                arrangement.revenueArrangementItem.forEach((item) => {
                    result[
                        item.id
                    ] = nextArrangement.revenueArrangementItem.find(
                        (nextItem) =>
                            (item.salesOrderItem.rootId ||
                                item.salesOrderItemId) ===
                            (nextItem.salesOrderItem.rootId ||
                                nextItem.salesOrderItemId)
                    );
                });
            }

            return result;
        },
        {}
    );

    if (revenueWithPPA == true) {
        // It will garuntees that PPA transaction will come first and after that other transaction will come
        // for current period
        transactions.sort(
            Comparator.getComparator(
                ["salesOrderItemId", "postingPeriod", "effectiveDate"],
                [
                    Comparator.forType("string"),
                    Comparator.forType("period"),
                    Comparator.forType("date")
                ]
            )
        );
    } else {
        transactions.sort(
            Comparator.getComparator(
                ["salesOrderItemId", "postingPeriod"],
                [Comparator.forType("string"), Comparator.forType("period")]
            )
        );
    }

    let beginningBalance = 0;
    let curSalesOrderItemId;
    let curSalesOrderItemRootId;

    let reportingTransaction;
    let reportingTransactions = [];

    const billingTransactions = [];

    // forces last transaction to be posted in loop
    transactions.push(endOfTransactions);

    let scheduleBySalesOrderItemId = prepareScheduleBySalesOrderItemId(
        salesOrder.billingSchedule
    );

    let adjustmentPeriods = [];
    const firstActgPeriod = Period.toActgPeriod(
        salesOrder.revenueArrangement[0].effectiveDate,
        calendarConfig
    );
    const salesOrderFirstActgPeriod = firstActgPeriod.period;

    salesOrder.revenueArrangement.forEach((revenueArrangement, index) => {
        let arrangementEndDate = revenueArrangement.effectiveDate;
        const effectiveActgPeriod = Period.toActgPeriod(
            revenueArrangement.effectiveDate,
            calendarConfig
        );
        let effectivePeriod = effectiveActgPeriod.period;

        // In case of modification, we dont want to use earlier arrangements to determine the adjustment period.
        // prior revenue arrangement is only used in cases where contract was
        // modified after the termination date (deliveryEndDate of the revenueArrangementItem with longest duration in
        // the prior arrangement).

        if (adjustmentPeriods[adjustmentPeriods.length - 1] > effectivePeriod) {
            adjustmentPeriods.pop();
        }

        // Set end date to the deliveryEndDate of the revenueArrangementItem with longest duration
        revenueArrangement.revenueArrangementItem.forEach((item) => {
            let itemEndDate = item.deliveryEndDate;
            if (!itemEndDate) return;

            if (
                !arrangementEndDate ||
                (arrangementEndDate &&
                    Period.isAfter(itemEndDate, arrangementEndDate))
            ) {
                arrangementEndDate = itemEndDate;
            }
        });
        const adjustmentActgPeriod = Period.toActgPeriod(
            arrangementEndDate,
            calendarConfig
        );
        adjustmentPeriods.push(adjustmentActgPeriod.period);
    });

    adjustmentPeriods = [...new Set(adjustmentPeriods)].sort();

    let lastPeriodDate = Period.toActgPeriod(
        adjustmentPeriods[adjustmentPeriods.length - 1],
        calendarConfig
    ).startDate;

    salesOrder.currentRevenueArrangement.revenueArrangementItem.forEach(
        (item) => {
            let itemEndDate = item.deliveryEndDate;
            let itemLatestBillingDate = itemEndDate;

            let itemSchedule =
                scheduleBySalesOrderItemId[
                    item.salesOrderItem.rootId || item.salesOrderItemId
                ];

            if (
                itemSchedule &&
                Array.isArray(itemSchedule.schedule) &&
                itemSchedule.schedule.length > 0
            ) {
                itemSchedule.schedule.forEach((schedule) => {
                    if (
                        !itemLatestBillingDate ||
                        (itemLatestBillingDate &&
                            schedule.billingDate &&
                            Period.isAfter(
                                schedule.billingDate,
                                itemLatestBillingDate
                            ))
                    ) {
                        itemLatestBillingDate = schedule.billingDate;
                    }
                });
            }
            //use the latest invoice as the item's latest billing date

            if (
                !lastPeriodDate ||
                (itemLatestBillingDate &&
                    lastPeriodDate &&
                    Period.isAfter(itemLatestBillingDate, lastPeriodDate))
            ) {
                lastPeriodDate = itemLatestBillingDate;
            }
        }
    );

    // salesOrderLastActgPeriod

    let lastActgPeriod = Period.toActgPeriod(lastPeriodDate, calendarConfig);
    let lastPeriod = lastActgPeriod.period;

    // Reversal Transactions Set
    const reversalTransactions = transactions.filter((transaction) => {
        if (
            !transaction.end &&
            transaction.transactionType === "REVENUE" &&
            transaction.processType === "REVERSAL"
        )
            return transaction;
    });

    reversalTransactions.sort(
        Comparator.getComparator(
            ["salesOrderItemId", "postingPeriod"],
            [Comparator.forType("string"), Comparator.forType("period")]
        )
    );

    const reversalTransactionsByItemId = Utils.groupByOnArray(
        reversalTransactions,
        (T) => T.salesOrderItemId
    );

    let currActgPeriod,
        ppaTransactionCount = 0,
        lasteffectiveDate,
        lastPostingPeriod;
    let revenue = 0,
        new_booking = 0;
    transactions.forEach((transaction) => {
        // Only report the unearned revenue component
        if (
            !transaction.end &&
            transaction.componentType != ComponentType.UNEARNED_REVENUE
        ) {
            return;
        }
        const currEffectiveDate = transaction.effectiveDate;

        if (
            curSalesOrderItemId != transaction.salesOrderItemId ||
            (currActgPeriod &&
                currActgPeriod.period != transaction.postingPeriod &&
                (revenueWithPPA == false ||
                    !lasteffectiveDate ||
                    lasteffectiveDate != currEffectiveDate ||
                    lastPostingPeriod != transaction.postingPeriod))
        ) {
            // currActgPeriod: last transaction's effective period
            // lasteffectiveDate: To aggregrate the revenue on transaction_Date level
            // lastPostingPeriod: used to diffrentiate the same effective date with-
            // different posting period for more details check REV-10258

            let salesOrderLastActgPeriod = adjustmentPeriods[0];
            if (
                currActgPeriod &&
                currActgPeriod.period > salesOrderLastActgPeriod &&
                adjustmentPeriods.length > 1
            ) {
                adjustmentPeriods.shift();
                salesOrderLastActgPeriod = adjustmentPeriods[0];
            }

            if (reportingTransaction) {
                if (ppaTransactionCount == 0) {
                    // We never calculate ending backlog for ppa transaction
                    reportingTransaction["Ending Backlog"] =
                        beginningBalance + new_booking - revenue;
                    revenue = 0;
                    new_booking = 0;
                }
                if (
                    curSalesOrderItemRootId == transaction.salesOrderItemRootId
                ) {
                    beginningBalance =
                        ppaTransactionCount == 0
                            ? reportingTransaction["Ending Backlog"]
                            : beginningBalance;
                    // This is a rolling over ending backlog to the next effective period
                } else {
                    beginningBalance = 0;
                }

                // ******* Adjust for Billing  ****** //

                let itemBillingSchedule =
                    scheduleBySalesOrderItemId[curSalesOrderItemRootId];
                if (itemBillingSchedule) {
                    const _reportingTransactions = adjustForBilling(
                        itemBillingSchedule,
                        reportingTransaction,
                        enableAdjustmentToUnearnedRevenue &&
                            currActgPeriod.period === salesOrderLastActgPeriod,
                        currActgPeriod,
                        salesOrderFirstActgPeriod
                    );
                    reportingTransaction = Utils.cloneDeep(
                        _reportingTransactions.revenue[0]
                    );

                    if (_reportingTransactions.billing.length > 0) {
                        billingTransactions.push(
                            ..._reportingTransactions.billing
                        );
                        const aggBillingTransactions = Utils.agg(
                            _reportingTransactions.billing,
                            ["Actg Period"],
                            ["Actg Period"],
                            [],
                            [],
                            ["New Billing"]
                        );
                        reportingTransaction["New Billing"] =
                            aggBillingTransactions[0]["New Billing"];
                    }
                    reportingTransactions.push(
                        _reportingTransactions.revenue[0]
                    );
                } else {
                    reportingTransactions.push(reportingTransaction);
                }

                // salesOrder item has changed.
                if (
                    curSalesOrderItemRootId != transaction.salesOrderItemRootId
                ) {
                    if (currActgPeriod.period < salesOrderLastActgPeriod) {
                        //todo @irfan need to understand what this is?
                        const rollTill = rollUnearnedRevenueTillLastPeriod(
                            currActgPeriod,
                            salesOrderLastActgPeriod,
                            reportingTransaction,
                            itemBillingSchedule,
                            false,
                            enableAdjustmentToUnearnedRevenue,
                            salesOrderFirstActgPeriod
                        );
                        rollTill.forEach((RT) => {
                            billingTransactions.push(...RT.billing);
                            reportingTransactions.push(...RT.revenue);
                        });
                    }

                    let lastReportingTransaction =
                        reportingTransactions[reportingTransactions.length - 1];
                    if (lastReportingTransaction["Actg Period"] < lastPeriod) {
                        const lastTransactionActgPeriod = currActgPeriod.get(
                            lastReportingTransaction["Actg Period"]
                        );
                        const rollTill = rollUnearnedRevenueTillLastPeriod(
                            lastTransactionActgPeriod,
                            lastPeriod,
                            lastReportingTransaction,
                            itemBillingSchedule,
                            true,
                            enableAdjustmentToUnearnedRevenue,
                            salesOrderFirstActgPeriod
                        );
                        rollTill.forEach((RT) => {
                            billingTransactions.push(...RT.billing);
                            reportingTransactions.push(...RT.revenue);
                        });
                    }
                } else if (transaction.isModification) {
                    // If there is any gap between current and last transaction period
                    // fill that gap from last till current - 1 period
                    let lastReversalTransaction =
                        reversalTransactionsByItemId[
                            transaction.salesOrderItemId
                        ];
                    if (
                        lastReversalTransaction &&
                        lastReversalTransaction.length
                    ) {
                        lastReversalTransaction =
                            lastReversalTransaction[
                                lastReversalTransaction.length - 1
                            ];
                        const nextTransactionPeriod =
                            lastReversalTransaction.actgPeriod.nextPeriod;
                        const currentPostingPeriod =
                            transaction.actgPeriod.period;

                        if (
                            !Period.isSame(
                                nextTransactionPeriod,
                                currentPostingPeriod
                            ) &&
                            !Period.isBefore(
                                currentPostingPeriod,
                                nextTransactionPeriod
                            )
                        ) {
                            // Add missing reportingTransactions till postingPeriod
                            const _reportingTransaction = Utils.copy(
                                reportingTransaction
                            );

                            const missingTransactions = getMissingReportingTransactions(
                                Period.toActgPeriod(
                                    nextTransactionPeriod,
                                    calendarConfig
                                ),
                                transaction.actgPeriod,
                                _reportingTransaction
                            );

                            reportingTransactions.push(...missingTransactions);
                        }
                    }
                }
            }

            curSalesOrderItemId = transaction.salesOrderItemId;
            curSalesOrderItemRootId = transaction.salesOrderItemRootId;
            if (revenueWithPPA && transaction.effectiveDate) {
                currActgPeriod = Period.toActgPeriod(
                    transaction.effectiveDate,
                    calendarConfig
                );
            }
            if (revenueWithPPA == false) {
                currActgPeriod = transaction.actgPeriod;
            }

            reportingTransaction = undefined;
        }

        if (transaction.end) return;

        lasteffectiveDate = currEffectiveDate;
        lastPostingPeriod = transaction.postingPeriod;
        if (!reportingTransaction) {
            let {
                postingPeriod,
                revenueArrangementItemId,
                salesOrderItemId,
                salesOrderItemRootId,
                isModification,
                effectiveDate,
                componentType,
                actgPeriod,
                is_PPA,
                effectivePeriod
            } = transaction;

            if (revenueWithPPA == true && is_PPA) {
                // used to avoid multiple booking
                ppaTransactionCount++;
            } else {
                ppaTransactionCount = 0;
            }
            let revenueArrangementItem =
                revenueArrangementItemById[revenueArrangementItemId];
            let raiStartActgPeriod = Period.toActgPeriod(
                revenueArrangementItem.effectiveDate,
                calendarConfig
            );

            if (recognizeByTransactionDate) {
                let transactionDate = revenueArrangementItem.transactionDate;

                if (transactionDate) {
                    const transactionDatePeriod = Period.toActgPeriod(
                        transactionDate,
                        calendarConfig
                    );

                    if (
                        transactionDatePeriod.period > raiStartActgPeriod.period
                    ) {
                        raiStartActgPeriod = transactionDatePeriod;
                    }
                }
            }

            const raiEndDate =
                revenueArrangementItem.endDate || Period.currentDate();
            const raiEndActgPeriod = Period.toActgPeriod(
                raiEndDate,
                calendarConfig
            );

            const raiAccountingPeriod = raiStartActgPeriod.period;
            const raiEndPeriod = raiEndActgPeriod.period;

            const { salesOrderItem } = revenueArrangementItem;

            reportingTransaction = {
                "Actg Period": actgPeriod.period,
                Quarter: actgPeriod.strQuarter,
                Year: actgPeriod.strYear,
                "Sales Order Id": salesOrder.id,
                "Sales Order Item Id": salesOrderItemId,
                "Sales Order Item Root Id": salesOrderItemRootId,
                "Customer Id": salesOrder.customerId,
                "Product Id": salesOrderItem.product.id,
                "Beginning Balance":
                    ppaTransactionCount > 0
                        ? 0
                        : Utils.parseAmount(beginningBalance),
                Revenue: 0,
                "Job Id": salesOrder.jobId,
                IsActive: revenueArrangementItem.isActive,
                isModification: isModification,
                "Adjustment To Unearned Revenue": 0,
                "Transaction Date": effectiveDate,
                "Component Type": componentType,
                revenueArrangementItemId: revenueArrangementItemId,
                "Reference Invoice Number":
                    (billingByContract &&
                        salesOrderItem.referenceInvoiceNumber) ||
                    "None",
                "Subscription ID":
                    (billingByContract && salesOrderItem.subscriptionId) ||
                    "None",
                "Invoice Number":
                    (billingByContract && salesOrderItem.invoiceNumber) ||
                    "None",
                "Is PPA": revenueWithPPA == true ? is_PPA : false,
                "Effective Period": effectivePeriod,
                "New Booking": 0,
                "New Unbilled": 0,
                "Ending Backlog": 0
            };
        }

        if (transaction.transactionType == TransactionType.REVENUE.name) {
            reportingTransaction["Revenue"] += transaction.amount;
            revenue += transaction.amount;
        } else if (transaction.transactionType == "REV_DEFERRAL") {
            new_booking += -1 * transaction.amount;
            reportingTransaction["New Booking"] += -1 * transaction.amount;
            reportingTransaction["New Unbilled"] +=
                transaction.extendedSalePrice;
        }
    });
    // remove dummy last transaction.
    transactions.pop();

    let period = "Actg Period";

    let orderRevenueDeferralByPeriod = Utils.agg(
        reportingTransactions,
        [period],
        [period],
        [],
        [],
        ["Beginning Net Deferral", "Net Deferral"],
        {
            "Net Deferral": (item) =>
                item["Ending Unearned Revenue"] -
                item["Adjustment To Unearned Revenue"],
            "Beginning Net Deferral": (item) =>
                item["Beginning Unearned Revenue"]
        }
    );

    orderRevenueDeferralByPeriod = Utils.arrayToObject(
        orderRevenueDeferralByPeriod,
        (item) => item[period],
        (item) => ({
            "Net Deferral": item["Net Deferral"],
            "Beginning Net Deferral": item["Beginning Net Deferral"]
        })
    );

    const populateByRollingSum = (
        data,
        aggregateBy,
        attribute,
        get,
        onCondition
    ) => {
        const sumByAggregate = new Map();
        data.map((t) => {
            const sum =
                get(t) +
                (sumByAggregate.has(t[aggregateBy])
                    ? sumByAggregate.get(t[aggregateBy])
                    : 0);
            if (onCondition(t)) {
                t[attribute] = sum;
            }
            sumByAggregate.set(t[aggregateBy], sum);
        });
    };

    const aggregateBy = "Sales Order Item Id";
    populateByRollingSum(
        reportingTransactions,
        aggregateBy,
        "Revenue LTD",
        (i) => i["Revenue"],
        (i) => i["Is PPA"] == 0
    );
    populateByRollingSum(
        reportingTransactions,
        aggregateBy,
        "New Booking LTD",
        (i) => i["New Booking"],
        (i) => i["Is PPA"] == 0
    );

    reportingTransactions.forEach((T) => {
        let beginningNetDeferral =
            orderRevenueDeferralByPeriod[T[period]]["Beginning Net Deferral"];
        let hadBeginningDeferral =
            beginningNetDeferral > 0 ||
            Utils.isNumberSame(beginningNetDeferral, 0);
        T["Beginning Net Deferral"] = beginningNetDeferral;
        T["Beginning Deferred Revenue"] = hadBeginningDeferral
            ? T["Beginning Unearned Revenue"]
            : 0;
        T["Beginning Unbilled Receivable"] = hadBeginningDeferral
            ? 0
            : -1 * T["Beginning Unearned Revenue"];

        let netDeferral =
            orderRevenueDeferralByPeriod[T[period]]["Net Deferral"];
        let hasDeferral = netDeferral > 0 || Utils.isNumberSame(netDeferral, 0);
        T[`Net Deferral`] = netDeferral;
        T["Ending Deferred Revenue"] = hasDeferral
            ? T["Ending Unearned Revenue"]
            : 0;
        T["Ending Unbilled Receivable"] = hasDeferral
            ? 0
            : -1 * T["Ending Unearned Revenue"];

        T["isBillingRow"] = false;
    });

    billingTransactions.forEach(
        (T) =>
            (T = Object.assign(T, {
                "Beginning Net Deferral": 0,
                "Beginning Deferred Revenue": 0,
                "Beginning Unbilled Receivable": 0,
                "Net Deferral": 0,
                "Ending Deferred Revenue": 0,
                "Ending Unbilled Receivable": 0,
                isBillingRow: true
            }))
    );

    let transactionsToReturn = [
        ...reportingTransactions,
        ...billingTransactions
    ];

    if (enableAdjustmentToUnearnedRevenue) {
        const finalTransactionItems = reportingTransactions.filter(
            (T) => T["Actg Period"] === lastPeriod
        );
        for (let T of finalTransactionItems) {
            if (
                T["Ending Unearned Revenue"] &&
                T["Ending Unearned Revenue"] !== 0.0
            ) {
                T["Adjustment To Unearned Revenue"] =
                    -1 * T["Ending Unearned Revenue"];
                T["Ending Unearned Revenue"] = 0.0;
            }
        }
    }
    salesOrder.reportingTransactions = transactionsToReturn;
}

// This function rolls unearned for each product item till the last period of the sales order.
// This ensures the snapshot at the customer level in each period has the correct total unearned number.
function rollUnearnedRevenueTillLastPeriod(
    currActgPeriod,
    lastPeriod,
    reportingTransaction,
    itemBillingSchedule,
    ensureZeroUnearnedRevenue = false,
    enableAdjustmentToUnearnedRevenue = false,
    salesOrderFirstActgPeriod
) {
    if (Period.isSameOrAfter(currActgPeriod.period, lastPeriod)) return [];

    // add empty transaction till last period for current sales order.
    const missingPeriods = Period.periodsBetween(
        currActgPeriod.period,
        lastPeriod,
        false,
        true
    );
    let actgPeriod = currActgPeriod;
    return missingPeriods.map((period) => {
        actgPeriod = actgPeriod.get(period);

        let currentReportingTransaction = Object.assign(
            {},
            reportingTransaction,
            {
                "Actg Period": actgPeriod.period,
                "Transaction Date": actgPeriod.effectiveDate,
                Quarter: actgPeriod.strQuarter,
                Year: actgPeriod.strYear,
                "Beginning Unearned Revenue":
                    reportingTransaction["Ending Unearned Revenue"],
                "Ending Unearned Revenue":
                    reportingTransaction["Ending Unearned Revenue"],
                "Beginning Unbilled": reportingTransaction["Ending Unbilled"],
                "Ending Unbilled": reportingTransaction["Ending Unbilled"],
                "Beginning Balance": 0,
                Revenue: 0,
                "New Booking": 0,
                "New Unbilled": 0,
                "New Billing": 0,
                "Effective Period": actgPeriod.period,
                "Is PPA": 0
            }
        );

        if (itemBillingSchedule) {
            currentReportingTransaction = adjustForBilling(
                itemBillingSchedule,
                currentReportingTransaction,
                enableAdjustmentToUnearnedRevenue &&
                    (period === lastPeriod || ensureZeroUnearnedRevenue),
                actgPeriod,
                salesOrderFirstActgPeriod
            );
        }

        return currentReportingTransaction;
    });
}

function getMissingReportingTransactions(
    fromActgPeriod,
    toActgPeriod,
    lastReportingTransaction
) {
    // add empty transaction till last period for current sales order.
    const missingPeriods = Utils.periodsBetween(
        fromActgPeriod.period,
        toActgPeriod.period,
        true
    );

    const missingReportingTransactions = [];

    let actgPeriod = fromActgPeriod;
    missingPeriods.forEach((period) => {
        actgPeriod = actgPeriod.get(period);

        let transaction = Object.assign({}, lastReportingTransaction, {
            "Actg Period": actgPeriod.period,
            "Transaction Date": actgPeriod.endDate,
            Quarter: actgPeriod.strQuarter,
            Year: actgPeriod.strYear,
            "Beginning Unearned Revenue":
                lastReportingTransaction["Ending Unearned Revenue"],
            "Booking Amount": 0,
            Revenue: 0,
            "Ending Unearned Revenue":
                lastReportingTransaction["Ending Unearned Revenue"],
            "Beginning Unbilled": 0,
            "New Billing": 0,
            "Ending Unbilled": 0,
            "Beginning Balance": 0,
            "New Booking": 0,
            "New Unbilled": 0,
            "Ending Backlog": 0,
            "Adjustment To Unearned Revenue": 0,
            "Effective Period": actgPeriod.period,
            "Is PPA": 0
        });

        missingReportingTransactions.push(transaction);
    });

    return missingReportingTransactions;
}
