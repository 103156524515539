import { Typography, Grid, Button } from "@material-ui/core";
import React from "react";
import { orgId, hasLicense } from "utils/auth";
import { setData2 } from "redux/actions";
import { SecurityUtils } from "revlock-webutils"
import { ImgIcon, A } from "components/ImgIcon"
import { showHGDialog } from "utils/WebUtils";
import * as PlgAPI from "rest/PlgAPI";

const listItem = (number, title, message, button, action) => {
    return (
        <Grid container>
            <Grid item xs={10}>
            <Typography variant={'subtitle1'}>{number}. <b>{title}</b></Typography>
            <Typography style={{ marginLeft: 14 }} color={'textSecondary'} variant={'subtitle2'} >
                {message}
            </Typography>
            </Grid>
            <Grid item xs={2}>
                <Button
                    style={{minWidth: 86}}
                    onClick={action}
                    variant='contained'
                    color='primary'>
                    {button}
                </Button>
            </Grid>
        </Grid>
    )
}

export const welcomeTour = (org, user, history) => {
    const welcomeIcon = () => ImgIcon({ name: 'welcome' });
    const syncIcon = () => ImgIcon({ name: 'sync' });
    const ledgerIcon = () => ImgIcon({ name: 'ledger' });

    let welcomeElement;
    if(hasLicense({ type: 'plan', name: 'rev-up' })) {
        welcomeElement = {
            icon: welcomeIcon,
            title: 'Welcome',
            subTitle: 'Setup Chargebee RevRec',
            content: <Grid style={{ minHeight: 440 }} item xs={6}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant={'h4'}>Get started with
                            RevRec</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            Start by learning how to navigate RevRec.
                        </Typography>
                        <br/>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={() => {
                                PlgAPI.disablePlg(async () => {
                                    setData2("showWelcomeTour", false);
                                    setData2("currentJoyRide", "basicTour");
                                    setData2("joyRideData",{ run: true });
                                })
                            }}>
                            Navigation Overview
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <br/>
                        <Typography variant="subtitle1">
                            Get a quick introduction to RevRec by
                            walking through our
                            <A
                              href="https://www.chargebee.com/docs/revrec.html">documentation</A> site.
                            <br/><br/>
                            This provides an overview of ASC 606 Revenue
                            Accounting and how it works in Chargebee.
                        </Typography>
                        <br/>
                    </Grid>
                </Grid>
            </Grid>
        }
    }
    else if(hasLicense({ type: 'plan', name: 'rev-essential' })){
        welcomeElement = {
            icon: welcomeIcon,
            title: 'Welcome',
            subTitle: 'Chargebee RevRec',
            content: <Grid style={{ minHeight: 440 }} item xs={6}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant={'h4'}>Get started with Chargebee RevRec</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            Start by learning how to navigate RevRec.
                        </Typography>
                        <br/>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={() => {
                                PlgAPI.disablePlg(async () => {
                                    setData2("showWelcomeTour", false);
                                    setData2("currentJoyRide", "basicTour");
                                    setData2("joyRideData",{ run: true });
                                })
                            }}>
                            Navigation Overview
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <br/>
                        <Typography variant="subtitle1">
                            <A
                              href="https://www.chargebee.com/docs/revrec/revrec-reporting.html">Learn more</A>
                            about Chargebee RevRec Reporting and all the features available with this package.
                        </Typography>
                        <br/>
                    </Grid>
                </Grid>
            </Grid>
        }
    }else {
        welcomeElement = {
            icon: welcomeIcon,
            title: 'RevRec',
            subTitle: 'Chargebee with RevRec: The perfect combo',
            content: <Grid style={{ minHeight: 440 }} item xs={6}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant={'subtitle1'}><b>Chargebee
                            billing</b> solved subscription
                            revenue. <b>RevRec</b> will take care of your
                            revenue accounting for you.
                            <br/><br/>
                            Read our <A
                              href="https://www.chargebee.com/docs/revrec.html">documentation</A> to
                            understand how this works.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            We have automatically brought your Chargebee
                            subscription and
                            billing data in, this includes all your plans and
                            transaction
                            information. We use this information to populate
                            your revenue
                            sub-ledger, making your financial reporting easy.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <br/>
                        <Typography variant="subtitle1">
                            As the data get comes in take a look at your key <A
                          href={'https://www.chargebee.com/docs/revrec-results.html#reviewing-results_revenue-recognition-results'}>results</A> in
                            the reporting area.
                        </Typography>
                        <br/>
                    </Grid>
                </Grid>
            </Grid>
        }
    };

    let syncElement;
    if (hasLicense({ type: 'plan', name: 'rev-up' })) {
        syncElement = {
            icon: syncIcon,
            title: 'Data',
            subTitle: 'Data Made Easy',
            content:
                <Grid style={{minHeight: 440}} item xs={6}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant={'h4'} >Data Made Easy</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'subtitle1'}>
                                It all starts with recording your sales. You can
                                    do this in one of <b style={{ color: 'black' }}>3 ways</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {listItem('1', 'Record sales directly',
                                (<>Record or edit your invoices and sales directly</>),
                                'Record ', () => {
                                    setData2("showWelcomeTour", false)
                                    history.push(`/${orgId()}/pages/sales`)
                                })}
                        </Grid>
                        <Grid>
                            {listItem('2', 'Import data transactions',
                                (<>Using our <A style={{ color: '#6200ea' }}
                                                href="https://www.chargebee.com/docs/revrec/importing-data-in-bulk.html">bulk upload</A>
                                    tool to import your data without connecting systems together</>),
                                'Import ', () => {
                                        setData2("showWelcomeTour", false)
                                        setData2("showImportDialog", true)
                                    })}
                        </Grid>
                        {(SecurityUtils.hasHotGlueAccess(org.id, user)) &&
                            <Grid>
                                {listItem('3', 'Setup automated syncs with your systems',
                                    (<><A style={{ color: '#6200ea' }}
                                        href="https://www.chargebee.com/docs/revrec/automated-data-synchronization.html">Connect</A>
                                        directly with your CRM, invoicing and other delivery systems</>),
                                    'Connect', () => {
                                            setData2("showWelcomeTour", false)
                                            showHGDialog({ orgId: org.id })
                                        })}
                            </Grid>
                        }
                    </Grid>
                </Grid>
        };
    }

    let ledgerElement;
    if(hasLicense({ type: 'plan', name: 'rev-up' })){
        ledgerElement = {
            icon: ledgerIcon,
            title: 'Ledger',
            subTitle: 'Integrate your Ledger',
            content:
              <Grid style={{minHeight: 440}} item xs={6}>
                  <Grid container>
                      <Grid item xs={12}>
                          <Typography variant={'h4'} >Integrate your Ledger</Typography>
                      </Grid>
                      <Grid item xs={12}>

                          <Typography variant={'subtitle1'} >
                              RevRec automatically generates accounting results into an internal <A href={"https://www.chargebee.com/docs/revrec-results.html#managing-revenue-subledger"}>sub-ledger</A>
                              to facilitate financial reporting.
                              <br /><br />
                              You now get your accounting numbers as detailed journal entries. You can review, verify or manually
                              post these into your accounting system. In addition you can setup posting rules that will automatically post these entries to your system.
                          </Typography>
                          <br />
                          <Typography variant={'subtitle1'}>
                              Learn how to <A style={{ color: '#6200ea' }} href="https://www.chargebee.com/docs/revrec-results.html#managing-revenue-subledger_managing-journal-entries">Post
                              Journal Entries</A> to your accounting system automatically.
                          </Typography>
                      </Grid>
                  </Grid>
              </Grid>
        }
    }

    const tour = [welcomeElement]
    if (syncElement) {
        tour.push(syncElement)
    }
    if (ledgerElement) {
        tour.push(ledgerElement)
    }

    return tour.filter(t => t);
}
