import { SecurityUtils } from "./SecurityUtils";

type Role = {
    name : string,
    description : string
}

export function add(x : number, y:number ) {
    let roles = SecurityUtils.getRoles() as Role[];
    return x + y;
}

export function subtract(x : number, y:number ) {
    let roles = SecurityUtils.getRoles() as Role[];
    return x - y;
}

export function multiple(x : number, y:number ) {
    let roles = SecurityUtils.getRoles() as Role[];
    return x * y;
}
